import styled from 'styled-components'

export const FormContainer = styled.div`
  padding: 20px;
`;

export const NumericContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const Spacing = styled.div`
  margin-bottom: 25px;
`;

export const SpacingMiddle = styled.div`
  margin-left: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;

  span: {
    width: 140px;
  }
`;