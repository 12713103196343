import styled from 'styled-components'

export const LoadingContainer = styled.div`
  padding: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const NumericContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
`;

export const Spacing = styled.div`
  margin-left: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  span: {
    width: 140px;
  }
`;

export const CategorieContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
`;

export const AddContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  span: {
    width: 140px;
  }
`;

export const TopSpacing = styled.div`
  margin-top: 20px;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  width: '50%';
`;

export const TimeLineContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: flex-start;
  align-items: flex-start;
  border-color: '#808080';
  border: 1px solid '#808080';
  border-radius: 5px;
`;