import Types from './types';

// Action Creators
export const Creators = {
  getPersons: (values) => ({ 
    type: Types.LOAD_MACHINE_LIST,
    payload: values
  }),

  getPersonsFiltered: (values) => ({ 
    type: Types.LOAD_PERSON_FILTERED_LIST,
    payload: values
  }),

  getDemands: (values) => ({
    type: Types.LOAD_DEMAND_LIST ,
    payload: values,
  }),

  getDemandsFiltered: (values) => ({
    type: Types.LOAD_DEMAND_FILTERED_LIST ,
    payload: values,
  }),

  getTotalMaintences: () => ({ type: Types.LOAD_TOTAL_MAINTENCE_COST }),

  getServices: () => ({ type: Types.LOAD_SERVICE_LIST }),

  getReportsIntoService: (id) => ({
    type: Types.LOAD_REPORT_INTO_SERVICE,
    payload: id
  }),

  createPerson: (values) => ({
    type: Types.CREATE_PERSON,
    payload: values
  }),

  createDemand: (values) => ({
    type: Types.CREATE_DEMAND,
    payload: values
  }),

  getUsers: (values) => ({
    type: Types.LOAD_USER_LIST,
    payload: values
  }),

  createUser: (values) => ({
    type: Types.CREATE_USER,
    payload: values
  }),
  
  createDaily: (values, categories) => ({
    type: Types.CREATE_DAILY,
    payload: { values, categories }
  }),

  createMaintence: (values, categoriesToUpdate, isGeneral) => ({
    type: Types.CREATE_MAINTENCE,
    payload: { values, categoriesToUpdate, isGeneral }
  }),

  createService: (values) => ({
    type: Types.CREATE_SERVICE,
    payload: values
  }),

  deleteService: (id) => ({
    type: Types.DELETE_SERVICE,
    payload: id
  }),

  insertReportIntoService: (values) => ({
    type: Types.CREATE_SERVICE_REPORT,
    payload: values
  })
};