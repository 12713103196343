import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { AuthProvider } from "./context/authContext"
import { Provider as ReduxProvider } from 'react-redux';
import store from './store';
import GlobalStyles from '../src/components/GlobalStyles';
import '../src/mixins/chartjs';
import theme from '../src/theme';
import routes from '../src/routes';

const App = () => {
  const routing = useRoutes(routes);

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <ReduxProvider store={store}>
            <GlobalStyles />
            {routing}
        </ReduxProvider>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
