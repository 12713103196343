import React, { useState } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Card, 
  Button,
  Typography,
  TextField,
  Divider,
  Snackbar,
} from '@material-ui/core';
import moment from 'moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from "date-fns/locale";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAuth } from '../../../context/authContext';
import { Creators } from '../../../store/pmp/actions';
import Page from '../../../../src/components/Page';
import Alert from '../../../../src/components/Alert';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { FormContainer, NumericContainer, CategorieContainer, Spacing, TopSpacing, ButtonContainer } from './styles';
import { toUpper } from 'lodash';

const useStyles = makeStyles((theme, rest) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  card: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  formControl: {
    marginTop: 2,
    minWidth: 300,
  },
}));

const AddMachineView = () => {
  const { currentUser } = useAuth();

  const officeId = sessionStorage.getItem('office-id');
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();

  const handleCreatePerson = (values, { resetForm }) => {
    const {name, phone, email, street, district, city, voterRegistration, zone, section} = values;
    const userId = currentUser.uid;
    const birthdate = moment(selectedDate).format('DD/MM/YYYY');
    const payload = {
      name: toUpper(name), phone, street, email, district, city, 
      birthdate, userId, voterRegistration, zone, section, officeId
    };
    dispatch(Creators.createPerson(payload));
    setOpen(true);
    resetForm({});
  };

  const [open, setOpen] = useState(false);
  const [openError,] = useState(false);
  const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11:54'));

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Page
      className={classes.root}
      title="Adicionar pessoas"
    >
      <Container maxWidth={false}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Pessoa cadastrada com sucesso!
        </Alert>
      </Snackbar>
      <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Erro ao cadastrar pessoa.
        </Alert>
      </Snackbar>
        <Box mt={3}>
          <Card >
            <FormContainer>
              <Formik
                initialValues={{
                  name: '',
                  phone: '',
                  maintencePeriod: 0,
                  maintenceType: '',
                  lastMaintence: 0,
                  email: '',
                  voterRegistration: '',
                  zone: '',
                  section: '',
                  street: '',
                  district: '',
                  city: ''
                }}
                validationSchema={
                  Yup.object().shape({
                    name: Yup.string().max(255).required('Nome da pessoa é obrigatório'),
                    phone: Yup.string().max(11).required('Telefone é obrigatório'),
                    email: Yup.string().max(50),
                    voterRegistration: Yup.string().max(12),
                    zone: Yup.string().max(3),
                    section: Yup.string().max(4),
                    street: Yup.string().max(50),
                    district: Yup.string().max(30),
                    city: Yup.string().max(30)
                  })
                }
                onSubmit={(values, resetForm) => {
                  handleCreatePerson(values, resetForm);
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  resetForm,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box sm={3}>
                      <Typography
                        color="textPrimary"
                        variant="h2"
                      >
                        Adicionar nova pessoa
                      </Typography>
                    </Box>
                    <NumericContainer>
                      <TextField
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                        label="Nome *"
                        margin="normal"
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        variant="outlined"
                        fullWidth
                      />
                      <Spacing />
                      <TextField
                        error={Boolean(touched.phone && errors.phone)}
                        helperText={touched.phone && errors.phone}
                        label="Telefone*"
                        placeholder="(xx)xxxxxxxxx"
                        margin="normal"
                        name="phone"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.phone}
                        variant="outlined"
                        type="number"
                        fullWidth
                      />
                    </NumericContainer>
                    <NumericContainer>
                      <TextField
                        fullWidth
                        label="Email"
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        variant="outlined"
                      />
                      <Spacing />
                      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                        <KeyboardDatePicker
                          fullWidth
                          disableFuture
                          inputVariant="outlined"
                          disableToolbar
                          format="dd/MM/yyyy"
                          margin="normal"
                          id="date-picker-inline"
                          label="Aniversário"
                          value={selectedDate}
                          onChange={handleDateChange}
                          KeyboardButtonProps={{
                            'aria-label': 'Mudar data',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </NumericContainer>
                    <NumericContainer>
                      <TextField
                          fullWidth
                          label="Título de eleitor"
                          margin="normal"
                          name="voterRegistration"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.voterRegistration}
                          variant="outlined"
                          type="number"
                        />
                      <Spacing />
                      <TextField
                        fullWidth
                        label="Zona"
                        margin="normal"
                        name="zone"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.zone}
                        variant="outlined"
                        type="number"
                      />
                      <Spacing />
                      <TextField
                        fullWidth
                        label="Seção"
                        margin="normal"
                        name="section"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.section}
                        variant="outlined"
                        type="number"
                      />
                    </NumericContainer>
                    <TopSpacing />
                    <Divider />
                    <Box style={{marginTop: 10}} sm={3}>
                      <Typography
                        color="textPrimary"
                        variant="h4"
                      >
                        Endereço
                      </Typography>
                    </Box>
                      <> 
                        <CategorieContainer>
                          <TextField
                            fullWidth
                            label="Rua"
                            margin="normal"
                            name="street"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.street}
                            variant="outlined"
                          />
                          <Spacing />
                          <TextField
                            fullWidth
                            label="Bairro"
                            margin="normal"
                            name="district"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.district}
                            variant="outlined"
                          />
                          <Spacing />
                          <TextField
                            fullWidth
                            label="Município"
                            margin="normal"
                            name="city"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.city}
                            variant="outlined"
                          />
                          <Spacing />
                        </CategorieContainer>
                      </>
                      <TopSpacing />
                    <Divider />
                    <TopSpacing />
                    <ButtonContainer>
                      <span>
                        <Button
                          color="primary"
                          fullWidth
                          size="large"
                          variant="outlined"
                          onClick={() => navigate('/app/pessoas')}
                        >
                          Voltar
                        </Button>
                      </span>
                      <span>
                        <Button
                          color="primary"
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Adicionar
                        </Button>
                      </span>
                    </ButtonContainer>
                  </form>
                )}
              </Formik>
            </FormContainer>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default AddMachineView;
