import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles,
  CircularProgress,
  Dialog,
  DialogContent,
  Button,
  Divider,
  Typography,
  TextField
} from '@material-ui/core';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux';
import { useAuth } from '../../../context/authContext';
import { Creators } from '../../../store/pmp/actions';
import Page from '../../../components/Page';
import { LoadingContainer } from './styles';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { TopSpacing, ButtonContainer} from './styles';
import 'react-big-calendar/lib/sass/styles.scss'
import { NumericContainer, Spacing } from '../../demands/AddDemandView/styles';
//import './styles.scss'
//import './prism.scss'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CalendarView = () => {
  const { demandList, loading } = useSelector((state) => {
    return state.pmpReducer;
  });
  const [events, setEvents] = useState([]);
  const [selectedEnd, setSelectedEnd] = useState();
  const [selectedStart, setSelectedStart] = useState();
  const [open, setOpen] = useState(false);
  const { currentUser } = useAuth();
  const dispatch = useDispatch();
  const classes = useStyles();
  const localizer = momentLocalizer(moment)

  useEffect(()  => {
    const userId = currentUser.uid;
    const payload = { userId };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatDate = (date) => {
    let newDate = new Date(date);
    const year = newDate.getFullYear();
    const month = addZero(newDate.getMonth() + 1).toString();
    const day = addZero(newDate.getDate().toString());
    const hour = addZero(newDate.getHours().toString());
    const minutes = addZero(newDate.getMinutes().toString());
    const fullString = `${year}-${month}-${day}T${hour}:${minutes}`
    return fullString;
  }

  const handleSelectEvent = ({ start, end }) => {
    const formatedEnd = formatDate(end);
    const formatStart = formatDate(start);
    setSelectedEnd(formatedEnd);
    setSelectedStart(formatStart);
    setOpen(true);
    //events.push({id: events.length+1, start, end, title: 'teste'});
    //setEvents(events);
  };

  const handleCreateEvent = (title) => {
    const start = new Date(selectedStart);
    const end = new Date(selectedEnd);
    events.push({id: events.length+1, start, end, title: title});
    setEvents(events);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const addZero = (number) => {
    if (number <= 9)
      return "0" + number;
    else 
      return number;
  };

  

  return (
    <Page
      className={classes.root}
      title="Agenda"
    >
      <Container maxWidth={false}>
        <Box mt={3}>
          {loading ? 
            (
              <LoadingContainer>
                <CircularProgress />
              </LoadingContainer>
            )
            :
            (
            <div>
              <Calendar
                selectable
                defaultView={Views.WEEK}
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                onSelectSlot={handleSelectEvent}
                onSelectEvent={event => alert(event.title)}
              />
            </div>)
          }
        </Box>
      </Container>
      <Dialog 
        open={open}
        fullWidth
        maxWidth={"md"}
        onClose={handleClose} 
        aria-labelledby="form-dialog-title">
        <Box >
          <DialogContent>
            <Formik
              initialValues={{
                title: '',
              }}
              validationSchema={
                Yup.object().shape({
                  title: Yup.string().max(255).required('Título do evento é obrigatório'),
                })
              }
              onSubmit={(values) => {
                handleCreateEvent(values.title);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box sm={3}>
                    <Typography
                      color="textPrimary"
                      variant="h2"
                    >
                      Criar evento
                    </Typography>
                  </Box>
                  <TextField
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                    label="Título do evento"
                    margin="normal"
                    name="title"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.title}
                    variant="outlined"
                    fullWidth
                  />
                  <NumericContainer>
                    <TextField
                      id="datetime-local"
                      fullWidth
                      label="Início"
                      type="datetime-local"
                      defaultValue={selectedStart}
                      onChange={ev => setSelectedStart(ev.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <Spacing />
                    <TextField
                      id="datetime-local"
                      fullWidth
                      label="Fim"
                      type="datetime-local"
                      defaultValue={selectedEnd}
                      onChange={ev => setSelectedEnd(ev.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </NumericContainer>
                  <TopSpacing />
                  <Divider />
                  <TopSpacing />
                  <ButtonContainer>
                    <span>
                      <Button
                        color="primary"
                        fullWidth
                        size="small"
                        variant="outlined"
                        onClick={() => setOpen(false)}
                      >
                        Voltar
                      </Button>
                    </span>
                    <span>
                      <Button
                        color="primary"
                        fullWidth
                        size="small"
                        type="submit"
                        variant="contained"
                      >
                        Salvar
                      </Button>
                    </span>
                  </ButtonContainer>
                </form>
              )}
            </Formik>
          </DialogContent>
        </Box>
      </Dialog>
    </Page>
  );
};

export default CalendarView;
