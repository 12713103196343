import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useAuth } from '../../context/authContext';
import { db, auth } from '../../config/firebase';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  CircularProgress,
  Link,
  TextField,
  Typography,
  makeStyles,
  Snackbar
} from '@material-ui/core';
import Page from '../../../src/components/Page';
import Alert from '../../../src/components/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CODE_ERROR_MSG = 'Código da empresa inválido';
const REGISTER_ERROR_MSG = 'Erro ao registrar conta!';
const EMAIL_SENT_MSG = 'Verifique seu e-mail para logar no sistema';

const RegisterView = () => {
  const classes = useStyles();
  const { signup, setCurrentUser } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Erro ao registrar conta');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleRegister = async ({name, companyId, email, password}) => {
    if (companyId === process.env.REACT_APP_COMPANY_CODE) {
      setLoading(true);
      try {
        await signup(email, password, name);
        const user = auth.currentUser;
        setCurrentUser({
          ...user,
          name: name,
          userType: 'admin'
        });
        const payload = {
          id: user.uid,
          email: email,
          name: name,
          userType: 'admin'
        };
        db.collection('users').doc(user.uid).set(payload);
        setLoading(true);
        user.sendEmailVerification().then(function() {
          
          setOpenSuccess(true);
          setLoading(false);
        }).catch(function(error) {
          setOpen(true);
          setLoading(false);
        });
        // navigate('/app/central', { replace: true });
      } catch(error) {
        console.log(error);
        setOpen(true);
        setErrorMessage(REGISTER_ERROR_MSG);
        setLoading(false);
      }
    } else {
      setOpen(true);
      setErrorMessage(CODE_ERROR_MSG);
    }
  }

  return (
    <Page
      className={classes.root}
      title="Register"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            {EMAIL_SENT_MSG}
          </Alert>
        </Snackbar>
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              name: '',
              password: '',
              companyId: '',
              policy: false
            }}
            validationSchema={
              Yup.object().shape({
                email: Yup.string().email('Deve ser um email válido').max(255).required('Email é obrigatório'),
                name: Yup.string().max(255).required('Nome é obrigatório'),
                password: Yup.string().max(255).min(6).required('Senha é obrigatório'),
                passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Senhas devem ser iguais'),
                companyId: Yup.string().max(255).required('Código da empresa é obrigatório'),
                policy: Yup.boolean().oneOf([true], 'Esse campo necessita ser marcado')
              })
            }
            onSubmit={(values) => {
              handleRegister(values)
              // navigate('/app/central', { replace: true });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Criar nova conta
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Use seu email para criar uma nova conta
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label="Nome"
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Senha"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
                  fullWidth
                  helperText={touched.passwordConfirmation && errors.passwordConfirmation}
                  label="Confirmação da Senha"
                  margin="normal"
                  name="passwordConfirmation"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.passwordConfirmation}
                  variant="outlined"
                />
                  <TextField
                  error={Boolean(touched.companyId && errors.companyId)}
                  fullWidth
                  helperText={touched.companyId && errors.companyId}
                  label="Código da Empresa"
                  margin="normal"
                  name="companyId"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.companyId}
                  variant="outlined"
                />
                <Box
                  alignItems="center"
                  display="flex"
                  ml={-1}
                >
                  <Checkbox
                    checked={values.policy}
                    name="policy"
                    onChange={handleChange}
                  />
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    Eu confirmo que li
                    {' '}
                    <Link
                      color="primary"
                      component={RouterLink}
                      to="#"
                      underline="always"
                      variant="h6"
                    >
                      os Termos e Condições
                    </Link>
                  </Typography>
                </Box>
                {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText error>
                    {errors.policy}
                  </FormHelperText>
                )}
                <Box my={2}>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={loading}
                  >
                    {loading ? <CircularProgress /> : 'Cadastrar'}
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Já possuo uma conta
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                  >
                    Entrar
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default RegisterView;
