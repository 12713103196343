import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { db } from '../../../config/firebase';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Divider,
  TextField,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Creators } from '../../../store/pmp/actions';
import { Formik } from 'formik';
import { NumericContainer, Spacing, TopSpacing, ButtonContainer} from './styles';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  formControl: {
    marginTop: 2,
    minWidth: 300,
  },
  modalEdit: {
    width: 900
  }
}));

const Results = ({ 
  className, Persons, userId, userRole, 
  page, setPage, limit, setLimit, arrayLength, ...rest }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [personToEdit, setPersonToEdit] = useState({});
  const [idToDelete, setIdToDelete] = useState();
  const dispatch = useDispatch();

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleEdit = (person) => {
    setPersonToEdit(person);
    setOpen(true);
  };

  const handleDelete = (id, name) => {
    setIdToDelete(id);
    setOpenDelete(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenDelete(false);
  };

  const handleEditPerson = (values) => {
    const officeId = sessionStorage.getItem('office-id');
    const { name, phone, email, birthdate, street, district, city, voterRegistration, zone, section } = values
    const docRef = db.collection('gabinete').doc(officeId).collection('person').doc(personToEdit.id);
    docRef.set({
      name,
      phone,
      email,
      birthdate,
      voterRegistration,
      zone,
      section,
      street, district, city
    }, { merge: true }).then(() => {
      setOpen(false);
      const payload = { officeId };
      dispatch(Creators.getPersons(payload));
    });
  };

  const handleDeletePerson = () => {
    const officeId = sessionStorage.getItem('office-id');
    try {
      db.collection('gabinete').doc(officeId).collection('person').doc(idToDelete).delete().then(function() {
        setOpenDelete(false);
        const payload = { officeId };
        dispatch(Creators.getPersons(payload));
      });
    } catch (error) {
      console.log(error);
    }
  };

  const isAdmin = () => {
    if (userRole === 'admin') return false;
    else return true;
  }

  const canEdit = () => {
    if (userRole !== 'normal') return false;
    else return true;
  };

  return (
    <>
    {Persons &&
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{fontWeight: 'bold'}}>
                  Nome
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                  Telefone
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                  Email
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                  Aniversário
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                  Endereço
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                  Editar
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                  Deletar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Persons.map((Person) => (
                <TableRow
                  hover
                  key={Person.id}
                >
                  <TableCell>
                    <Box
                      alignItems="center"
                      display="flex"
                    >
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {Person.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {Person.phone}
                  </TableCell>
                  <TableCell>
                    {Person.email === null ? ' - ' : Person.email}
                  </TableCell>
                  <TableCell>
                    {Person.birthdate}
                  </TableCell>
                  <TableCell>
                    {`${Person.street} - ${Person.district} - ${Person.city}`}
                  </TableCell>
                  <TableCell>
                    <IconButton 
                      aria-label="expand row" 
                      disabled={canEdit()}
                      size="small" 
                      onClick={() => handleEdit(Person)} >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton 
                      aria-label="expand row" 
                      size="small" 
                      onClick={() => handleDelete(Person.id, Person.name)}
                      disabled={isAdmin()}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={arrayLength}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Linhas por página"
      />
      <Dialog
        open={openDelete}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box>
          <DialogTitle id="alert-dialog-title">{"Deseja excluir essa pessoa?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Essa ação será irreversível.
            </DialogContentText>
          </DialogContent>
          <ButtonContainer>
            <span>
              <Button 
                onClick={handleClose} 
                color="primary"
              >
                Cancelar
              </Button>
            </span>
            <span>
              <Button 
                onClick={handleDeletePerson} 
                color="secondary" 
                autoFocus
              >
                Deletar
              </Button>
            </span>
          </ButtonContainer>
        </Box>
      </Dialog>
      <Dialog 
        open={open}
        fullWidth
        maxWidth={"md"}
        onClose={handleClose} 
        aria-labelledby="form-dialog-title">
        <Box >
          <DialogContent>
            <Formik
              initialValues={{
                name: personToEdit.name,
                phone: personToEdit.phone,
                birthdate: personToEdit.birthdate,
                email: personToEdit.email,
                voterRegistration: personToEdit.voterRegistration,
                zone: personToEdit.zone,
                section: personToEdit.section,
                street: personToEdit.street,
                district: personToEdit.district,
                city: personToEdit.city,
              }}
              validationSchema={
                Yup.object().shape({
                  name: Yup.string().max(255).required('Nome da pessoa é obrigatório'),
                  phone: Yup.number().required('Telefone é obrigatório'),
                  voterRegistration: Yup.string().max(12),
                  zone: Yup.string().max(3),
                  section: Yup.string().max(4),
                  birthdate: Yup.string().required('Aniversário é obrigatório')
                })
              }
              onSubmit={(values) => {
                handleEditPerson(values);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box sm={3}>
                    <Typography
                      color="textPrimary"
                      variant="h2"
                    >
                      Editar cadastro de {personToEdit.name}
                    </Typography>
                  </Box>
                  <NumericContainer>
                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      label="Nome"
                      margin="normal"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                      fullWidth
                    />
                    <Spacing />
                    <TextField
                      error={Boolean(touched.phone && errors.phone)}
                      helperText={touched.phone && errors.phone}
                      label="Telefone"
                      margin="normal"
                      name="phone"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.phone}
                      variant="outlined"
                      fullWidth
                    />
                  </NumericContainer>
                  <NumericContainer>
                    <TextField
                      fullWidth
                      label="Email"
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      variant="outlined"
                    />
                    <Spacing />
                    <TextField
                      fullWidth
                      label="Aniversário"
                      margin="normal"
                      name="birthdate"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.birthdate}
                      variant="outlined"
                    />
                  </NumericContainer>
                  <NumericContainer>
                      <TextField
                          fullWidth
                          label="Título de eleitor"
                          margin="normal"
                          name="voterRegistration"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.voterRegistration}
                          variant="outlined"
                          type="number"
                        />
                      <Spacing />
                      <TextField
                        fullWidth
                        label="Zona"
                        margin="normal"
                        name="zone"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.zone}
                        variant="outlined"
                        type="number"
                      />
                      <Spacing />
                      <TextField
                        fullWidth
                        label="Seção"
                        margin="normal"
                        name="section"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.section}
                        variant="outlined"
                        type="number"
                      />
                    </NumericContainer>
                  <TopSpacing />
                    <Divider />
                    <Box style={{marginTop: 10}} sm={3}>
                      <Typography
                        color="textPrimary"
                        variant="h4"
                      >
                        Endereço
                      </Typography>
                    </Box>
                  <NumericContainer>
                    <TextField
                      fullWidth
                      label="Rua"
                      margin="normal"
                      name="street"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.street}
                      variant="outlined"
                    />
                    <Spacing />
                    <TextField
                      fullWidth
                      label="Bairro"
                      margin="normal"
                      name="district"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.district}
                      variant="outlined"
                    />
                    <Spacing />
                    <TextField
                      fullWidth
                      label="Município"
                      margin="normal"
                      name="city"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.city}
                      variant="outlined"
                    />
                  </NumericContainer>
                  <TopSpacing />
                  <ButtonContainer>
                    <span>
                      <Button
                        color="primary"
                        fullWidth
                        size="small"
                        variant="outlined"
                        onClick={() => setOpen(false)}
                      >
                        Voltar
                      </Button>
                    </span>
                    <span>
                      <Button
                        color="primary"
                        fullWidth
                        size="small"
                        type="submit"
                        variant="contained"
                      >
                        Salvar
                      </Button>
                    </span>
                  </ButtonContainer>
                </form>
              )}
            </Formik>
          </DialogContent>
        </Box>
      </Dialog>
    </Card>
  }
  </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  Persons: PropTypes.array.isRequired
};

export default Results;
