import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth } from '../../../context/authContext';
import { Creators } from '../../../store/pmp/actions';
import Page from '../../../../src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { LoadingContainer } from './styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const DemandListView = () => {
  const { demandList, loading } = useSelector((state) => {
    return state.pmpReducer;
  });
  const { currentUser, officeId, userRole } = useAuth();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [array, setArray] = useState(demandList);

  useEffect(()  => {
    const userId = currentUser.uid;
    const payload = { userId, officeId };
    dispatch(Creators.getDemands(payload));
    setLimit(10);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const paginate = (array, page_size, page_number) => {
    return array.slice(page_number * page_size, page_number * page_size + page_size);
  };

  useEffect(() => {
    const arr = paginate(demandList, limit, page);
    setArray(arr);
  },[page, limit]);

  useEffect(() => {
    const arr = paginate(demandList, limit, page);
    setArray(arr);
  }, [demandList])

  return (
    <Page
      className={classes.root}
      title="Demandas"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          {loading ? 
            (
              <LoadingContainer>
                <CircularProgress />
              </LoadingContainer>
            )
            :
            (array && 
            <Results 
              Demands={array} 
              userId={currentUser.uid} 
              userRole={userRole}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              arrayLength={demandList.length}
            />)
          }
        </Box>
      </Container>
    </Page>
  );
};

export default DemandListView;
