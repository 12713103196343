import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { auth } from '../../../config/firebase';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';

const userMock = {
  avatar: '/static/images/avatars/gustavo.jpg',
  city: 'Luziânia',
  country: 'Brasil',
  jobTitle: 'Administrador',
  name: 'Gustavo Braz',
  timezone: 'GTM-3'
};

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  }
}));

const Profile = ({ className, ...rest }) => {
  const classes = useStyles();
  const user = auth.currentUser;

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Avatar
            className={classes.avatar}
            src={user.avatar}
          />
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {user.displayName}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            {`${userMock.city} ${userMock.country}`}
          </Typography>
          <Typography
            className={classes.dateText}
            color="textSecondary"
            variant="body1"
          >
            {`${moment().format('hh:mm A')} ${userMock.timezone}`}
          </Typography>
        </Box>
        <CardActions>
          <Button
            color="primary"
            fullWidth
            variant="text"
            disabled
          >
            Atualizar foto
          </Button>
        </CardActions>
      </CardContent>
      <Divider />
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
