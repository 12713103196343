import Types from './types';

const INITIAL_STATE = {
  loading: false,
  modalLoading: false,
  error: false,
  personList: [{}],
  userList: [{}],
  demandList: [{}],
  serviceList: [{}],
  totalOfMaintence: null,
  reportsIntoService: [{}],
  user: {}
}

// Reducer
export default function pmpReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.CREATE_USER_COMPLETED:
      return {
        ...state,
        user: action.payload.user
      }
    case Types.LOAD_MACHINE_LIST:
      return {
        ...state,
        loading: true
      }
    case Types.LOAD_USER_LIST:
      return {
        ...state,
        loading: true
      }
    case Types.LOAD_USER_LIST_COMPLETED:
      return {
        ...state,
        userList: action.payload.userList,
        error: false,
        loading: false
      };
    case Types.LOAD_MACHINE_LIST_COMPLETED:
      return {
        ...state,
        personList: action.payload.personList,
        error: false,
        loading: false
      };
    case Types.LOAD_PERSON_FILTERED_LIST:
      return {
        ...state,
        loading: true
      }
    case Types.LOAD_PERSON_FILTERED_LIST_COMPLETED:
      return {
        ...state,
        personList: action.payload.personList,
        error: false,
        loading: false
      };
    case Types.LOAD_PERSON_FILTERED_LIST_ERROR:
      return {
        ...state,
        personList: action.payload.personList,
        error: false,
        loading: false
      };
    case Types.LOAD_DEMAND_FILTERED_LIST:
      return {
        ...state,
        loading: true
      }
    case Types.LOAD_DEMAND_FILTERED_LIST_COMPLETED:
      return {
        ...state,
        demandList: action.payload.demandList,
        error: false,
        loading: false
      };
    case Types.LOAD_DEMAND_FILTERED_LIST_ERROR:
      return {
        ...state,
        demandList: action.payload.demandList,
        error: false,
        loading: false
      };
    case Types.LOAD_DEMAND_LIST:
        return {
          ...state,
          loading: true
        }
      case Types.LOAD_DEMAND_LIST_COMPLETED:
        return {
          ...state,
          demandList: action.payload.demandList,
          error: false,
          loading: false
        };
    case Types.LOAD_TOTAL_MAINTENCE_COST:
      return {
        ...state,
        loading: true
      }
    case Types.LOAD_TOTAL_MAINTENCE_COST_COMPLETED:
      return {
        ...state,
        loading: false,
        totalOfMaintence: action.payload.totalOfMaintence
      }
    case Types.CREATE_SERVICE_COMPLETED:
      return {
        ...state,
        loading: false
      }
    case Types.LOAD_SERVICE_LIST_COMPLETED:
      return {
        ...state,
        loading: false,
        serviceList: action.payload.serviceList
      }
    case Types.LOAD_REPORT_INTO_SERVICE:
      return {
        ...state,
        modalLoading: true
      }

    case Types.LOAD_REPORT_INTO_SERVICE_COMPLETED:
      return {
        ...state,
        reportsIntoService: action.payload.reportsIntoService,
        modalLoading: false
      }
    default:
      return state;
  }
}