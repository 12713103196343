import styled from 'styled-components'

export const FormContainer = styled.div`
  padding: 20px;
`;

export const NumericContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
`;

export const CategorieContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
`;

export const Spacing = styled.div`
  margin-left: 20px;
`;

export const TopSpacing = styled.div`
  margin-top: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  span: {
    width: 140px;
  }
`;

export const AddContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  span: {
    width: 140px;
  }
`;