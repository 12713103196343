import React, { useState } from 'react';
import {
  Box,
  Snackbar,
  Typography,
  TextField,
  Button,
  InputAdornment,
  CircularProgress,
  InputLabel,
  Select,
  Switch,
  FormControl,
  Input,
  Chip,
  MenuItem
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { Formik } from 'formik';
import moment from 'moment';
import Alert from '../../../../src/components/Alert';
import { useSelector, useDispatch } from 'react-redux';
import { Creators } from '../../../store/pmp/actions';
import { ButtonContainer, FormContainer, NumericContainer, Spacing } from './styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 320,
    maxWidth: 400,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const MaintenceReport = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { machineList, loading } = useSelector((state) => {
    return state.pmpReducer;
  });
  const dispatch = useDispatch();
  const [machineId] = useState();
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [open, setOpen] = useState(false);
  const date = moment().format('DD/MM/YYYY');
  const [isGeneral, setIsGeneral] = useState(false);
  const [maintenceCategories, setMaintenceCategories] = useState([]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const selectItem = (event) => {
    setSelectedMachine(event.target.value);
  };

  const handleChangeCategorie = (event) => {
    setMaintenceCategories(event.target.value);
  };

  const updateCategories = (toUpdate, selectedMachine) => {
    if (toUpdate) {
      let newArray = [...selectedMachine.maintenceCategories];
      toUpdate.forEach((toUpdateValue) => {
        const index = selectedMachine.maintenceCategories.findIndex((obj => obj.name === toUpdateValue));
        if (selectedMachine.maintenceCategories.maintenceType === 'HR') {
          newArray[index].lastMaintence = selectedMachine.hourMeterCurrent;
          newArray[index].indexMaintence = - newArray[index].maintencePeriod;
        } else {
          newArray[index].lastMaintence = selectedMachine.miliageCurrent;
          newArray[index].indexMaintence = - newArray[index].maintencePeriod;
        };
      });
      return newArray;
    } else { 
      return selectedMachine.maintenceCategories;
    };
  };

  const handleCreateMaintence = (values, resetForm) => {
    const categoriesToUpdate = updateCategories(maintenceCategories, selectedMachine);
    let miliageHour;
    const { maintenceValue, description  } = values;
    if (selectedMachine.maintenceType === 'HR') {
      miliageHour = selectedMachine.hourMeterCurrent;
    } else {
      miliageHour = selectedMachine.miliageCurrent
    }
    const payload = {
      machineId: selectedMachine.id,
      maintencePeriod: selectedMachine.maintencePeriod,
      maintenceValue,
      description,
      miliageHour,
      createdAt: moment().format()
    }
    dispatch(Creators.createMaintence(payload, categoriesToUpdate, isGeneral));
    setOpen(true);
    resetForm({});
  };

  return (
    <FormContainer>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Manutenção inserida com sucesso!
        </Alert>
      </Snackbar>
      <Formik
        initialValues={{
          description: null,
          maintenceValue: null,
        }}
        validationSchema={
          Yup.object().shape({
            description: Yup.string().max(255).required('Descrição é obrigatório'),
          })
        }
        onSubmit={(values, resetForm) => {
          handleCreateMaintence(values, resetForm);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Box sm={3}>
              <Typography
                color="textPrimary"
                variant="h2"
              >
                Relatar manutenção
              </Typography>
            </Box>
            <Spacing />
            { loading ? <CircularProgress /> :
              <Box>
                <InputLabel id="demo-simple-select-label">Selecione a máquina</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={machineId}
                  onChange={selectItem}
                  fullWidth
                  variant="outlined"
                >
                  { machineList.map((item) => (
                    <MenuItem value={item}>{item.name}</MenuItem>
                  ))}
                </Select>
              </Box>
            }
            { selectedMachine !== null ? 
              <Box>
                <NumericContainer>
                  <Box>
                    <Spacing />
                    <Typography
                      color="textPrimary"
                      variant="subtitle1"
                    >
                      {`A manutenção terá a data de: ${date}`}
                    </Typography>

                    <Typography
                      color="textPrimary"
                      variant="subtitle1"
                    >
                      {(selectedMachine.maintenceType === 'KM') ?
                        `Quilometragem atual da máquina: ${selectedMachine.miliageCurrent}` :
                        `Horímetro atual da máquina: ${selectedMachine.hourMeterCurrent}`
                      }
                    </Typography>
                  </Box>
                  {selectedMachine.maintenceCategories &&
                    <Box>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-mutiple-chip-label">Categorias de manutenção</InputLabel>
                      <Select
                        labelId="demo-mutiple-chip-label"
                        id="demo-mutiple-chip"
                        multiple
                        value={maintenceCategories}
                        onChange={(event) => handleChangeCategorie(event)}
                        input={<Input id="select-multiple-chip" />}
                        renderValue={(selected) => (
                          <div className={classes.chips}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} className={classes.chip} />
                            ))}
                          </div>
                        )}
                        MenuProps={MenuProps}
                      >
                        {selectedMachine.maintenceCategories.map((categorie) => (
                          <MenuItem key={categorie.id} value={categorie.name} style={getStyles(categorie, maintenceCategories, theme)}>
                            {categorie.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    </Box>
                  }
                  <Box>
                    <TextField
                      fullWidth
                      InputProps={{
                        endAdornment: <InputAdornment position="end">R$</InputAdornment>
                      }}
                      label="Valor da manutenção"
                      margin="normal"
                      name="maintenceValue"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.maintenceValue}
                      variant="outlined"
                      type="number"
                    />
                  </Box>
                </NumericContainer>
                <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                  <Typography
                    color="textPrimary"
                    variant="subtitle1"
                  >
                    Deseja relatar essa manutenção como geral da máquina?
                  </Typography>
                  <Switch
                    checked={isGeneral}
                    onChange={(event) => setIsGeneral(event.target.checked)}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </div>
                <TextField
                  label="Descrição"
                  margin="normal"
                  name="description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                />
              </Box>
              :
              <Box></Box>
            }
            <Spacing />
            <ButtonContainer>
              <span>
                <Button
                  color="primary"
                  fullWidth
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Adicionar
                </Button>
              </span>
            </ButtonContainer>
          </form>
        )}
      </Formik>
    </FormContainer>
  )
}

export default MaintenceReport;