import React, { useState } from 'react';
import {
  Box,
  Snackbar,
  Typography,
  TextField,
  Button,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress
} from '@material-ui/core';
import * as Yup from 'yup';
import moment from 'moment';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { Creators } from '../../../store/pmp/actions';
import Alert from '../../../../src/components/Alert';
import { ButtonContainer, FormContainer, NumericContainer, Spacing, SpacingMiddle } from './styles';

const DailyReport = () => {
  const { machineList, serviceList, loading } = useSelector((state) => {
    return state.pmpReducer;
  });
  const dispatch = useDispatch();
  const [machineId] = useState();
  const [serviceId] = useState();
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [minimunValue, setMinimunValue] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const selectItem = (event, setFieldValue) => {
    setSelectedMachine(event.target.value);
    if ( event.target.value.maintenceType === 'HR') {
      setFieldValue('valueInitial', event.target.value.hourMeterCurrent);
      setMinimunValue(event.target.value.hourMeterCurrent);
    } else {
      setFieldValue('valueInitial', event.target.value.miliageCurrent);
      setMinimunValue(event.target.value.miliageCurrent);
    }
  };

  const selectService = (event) => {
    setSelectedService(event.target.value);
  };

  const handleCreateDaily = (values, { resetForm }) => {
    const { valueInitial, valuesFinal, fillLiters, destiny, responsible } = values
    const payload = {
      valueInitial,
      valuesFinal,
      fillLiters,
      destiny,
      responsible,
      machineId: selectedMachine.id,
      maintencePeriod: selectedMachine.maintencePeriod,
      lastMaintence: selectedMachine.lastMaintence,
      valueDif: valuesFinal - valueInitial,
      maintenceType: selectedMachine.maintenceType,
      miliageCurrent: selectedMachine.miliageCurrent,
      hourMeterCurrent: selectedMachine.hourMeterCurrent,
      createdAt: moment().format()
    };
    dispatch(Creators.createDaily(payload, selectedMachine.maintenceCategories));
    if (selectedService) {
      const report = {
        serviceId: selectedService.id,
        machineId: selectedMachine.id,
        machineName: selectedMachine.name,
        valueDif: valuesFinal - valueInitial,
        hourMeterTotal: selectedService.hourMeterTotal,
        miliageTotal: selectedService.miliageTotal,
        fillLiters,
        maintenceType: selectedMachine.maintenceType,
        createdAt: moment().format()
      }
      dispatch(Creators.insertReportIntoService(report))
    }
    setOpen(true);
    resetForm({});
  };

  return (
    <FormContainer>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Relatório diário inserido com sucesso!
        </Alert>
      </Snackbar>
      <Formik
        initialValues={{
          machineId: "",
          valueInitial: null,
          valuesFinal: null,
          fillLiters: null,
          destiny: null,
          responsible: null,
        }}
        validationSchema={
          Yup.object().shape({
            destiny: Yup.string().max(255),
            responsible: Yup.string().max(255),
            valueInitial: Yup.number()
              .min(minimunValue, 'Valor inicial precisa ser maior do que atual da máquina')
              .required('Valor inicial é obrigatório'),
            valuesFinal: Yup.number()
              .min(minimunValue + 1, 'Valor final precisa ser maior do que inicial')
              .required('Valor final é obrigatório'),
            fillLiters: Yup.number().min(0, 'Abastacimento precisa ser maior do que zero'),

          })
        }
        onSubmit={(values, resetForm) => {
          handleCreateDaily(values, resetForm);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
          setFieldValue,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Box sm={3}>
              <Typography
                color="textPrimary"
                variant="h2"
              >
                Inserir Relatório Diário
              </Typography>
            </Box>
            <Spacing />
            { loading ? <CircularProgress /> :
              <Box>
                <InputLabel id="demo-simple-select-label">Selecione a máquina</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={machineId}
                  onChange={ev => selectItem(ev, setFieldValue)}
                  fullWidth
                  variant="outlined"
                >
                  { machineList.map((item) => (
                    <MenuItem value={item}>{item.name}</MenuItem>
                  ))}
                </Select>
              </Box>
            }
            { selectedMachine !== null ? 
              <Box>
                <NumericContainer>
                  <TextField
                    error={Boolean(touched.valueInitial && errors.valueInitial)}
                    helperText={touched.valueInitial && errors.valueInitial}
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">KM/Hr</InputAdornment>
                    }}
                    label="KM/HR Inicial"
                    margin="normal"
                    name="valueInitial"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.valueInitial}
                    variant="outlined"
                    type="number"
                    min="1"
                  />
                  <SpacingMiddle />
                  <TextField
                    error={Boolean(touched.valuesFinal && errors.valuesFinal)}
                    helperText={touched.valuesFinal && errors.valuesFinal}
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">KM/Hr</InputAdornment>
                    }}
                    label="KM/HR Final"
                    margin="normal"
                    name="valuesFinal"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.valuesFinal}
                    variant="outlined"
                    type="number"
                  />
                  <SpacingMiddle />
                  <TextField
                    error={Boolean(touched.model && errors.model)}
                    helperText={touched.model && errors.model}
                    fullWidth
                    InputProps={{
                      endAdornment: <InputAdornment position="end">LTs</InputAdornment>
                    }}
                    label="Abastecimento"
                    margin="normal"
                    name="fillLiters"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.fillLiters}
                    variant="outlined"
                    type="number"
                  />
                </NumericContainer>
                <TextField
                  label="Destino"
                  margin="normal"
                  name="destiny"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.destiny}
                  variant="outlined"
                  fullWidth
                />
                <TextField
                  label="Responsável"
                  margin="normal"
                  name="responsible"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.responsible}
                  variant="outlined"
                  fullWidth
                />
                <Spacing />
                <InputLabel id="demo-simple-select-label">Associar Relatório a um serviço:</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={serviceId}
                  onChange={selectService}
                  fullWidth
                  variant="outlined"
                >
                  { serviceList.map((item) => (
                    <MenuItem value={item}>{item.name}</MenuItem>
                  ))}
                </Select>
              </Box>
              :
              <Box></Box>
            }
            <Spacing />
            <ButtonContainer>
              <span>
                <Button
                  color="primary"
                  fullWidth
                  size="medium"
                  type="submit"
                  variant="contained"
                >
                  Inserir
                </Button>
              </span>
            </ButtonContainer>
          </form>
        )}
      </Formik>
    </FormContainer>
  )
}

export default DailyReport;