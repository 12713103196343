import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { db } from '../../../config/firebase';
import InfoIcon from '@material-ui/icons/Info';
import { isEmpty } from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  Chip,
  TextField,
  FormLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Divider,
  Paper
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from '@material-ui/lab/TimelineDot';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from "date-fns/locale";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Creators } from '../../../store/pmp/actions';
import { Formik } from 'formik';
import { TopSpacing, ButtonContainer, AddContainer, Spacing, ActionContainer } from './styles';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  formControl: {
    marginTop: 2,
    minWidth: 300,
  },
  modalEdit: {
    width: 900
  },
  paper: {
    padding: '6px 16px',
  },
}));

const Results = ({ className, Demands, userId, userRole,
  arrayLength, page, setPage, limit, setLimit, ...rest }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [demandToEdit, setDemandToEdit] = useState({});
  const [priorityToEdit, setPriorityToEdit] = useState('a');
  const [actionsToEdit, setActionsToEdit] = useState([]);
  const [demandDetail, setDemandDetail] = useState({});
  const [idToDelete, setIdToDelete] = useState();
  const dispatch = useDispatch();
  const userName = sessionStorage.getItem('user-name');

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleEdit = (demand) => {
    setDemandToEdit(demand);
    setActionsToEdit(demand.actions);
    setOpen(true);
  };

  const handleDelete = (id, name) => {
    setIdToDelete(id);
    setOpenDelete(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenDelete(false);
    setOpenDetails(false);
  };

  const handleEditDemand = (values) => {
    const officeId = sessionStorage.getItem('office-id');
    const { description, local } = values
    const docRef = db.collection('gabinete').doc(officeId).collection('demands').doc(demandToEdit.id);
    let newPriority;
    const actions = actionsToEdit;
    let concludedDate = '';
    if (priorityToEdit === 'a') {
      newPriority = 2;
    }
    if (priorityToEdit === 'b') {
      newPriority = 1;
    }
    if (priorityToEdit === 'c') {
      newPriority = 3;
      concludedDate = moment().format();
    }
    docRef.set({
      description,
      local,
      priority: newPriority,
      updatedAt: moment().format(),
      concludedDate,
      actions
    }, { merge: true }).then(() => {
      setOpen(false);
      const payload = { officeId };
      dispatch(Creators.getDemands(payload));
    });
  };

  const handleDeleteDemand = () => {
    const officeId = sessionStorage.getItem('office-id');
    try {
      db.collection('gabinete').doc(officeId).collection('demands').doc(idToDelete).delete().then(function() {
        setOpenDelete(false);
        const payload = { officeId };
        dispatch(Creators.getDemands(payload));
      });
    } catch {
      // nothing
    }
  };

  const handleDateChange = (date, index) => {
    const newActions = actionsToEdit.map((action,sIndex) => {
      if (index !== sIndex) return action;
      return { 
        ...action, 
        date: moment(date).format(),
      };
    })
    setActionsToEdit(newActions);
  };

  const handleAddAction= () => {
    if (isEmpty(actionsToEdit)) {
      setActionsToEdit([{
        description: '',
        date: moment().format(),
        userName,
        newAction: true
      }]);
    } else {
      const transformed = [...actionsToEdit];
      transformed.push({
        description: '',
        date: moment().format(),
        userName,
        newAction: true
      });
      setActionsToEdit(transformed);
    }
  };

  const checkCanEdit = (action) => {
    console.log(action);
    if (userRole === 'normal' && !action.newAction) {
      return true;
    }
    return false;
  };

  const handleSetActionDescription = (index, event) => {
    const newActions = actionsToEdit.map((action,sIndex) => {
      if (index !== sIndex) return action;
      return { 
        ...action, 
        description: event.target.value,
      };
    })
    setActionsToEdit(newActions);
  };

  const handleRemoveAction = (index) => {
    setActionsToEdit(
      actionsToEdit.filter((s, sindex) => index !== sindex)
    )
  };

  const getColor = (priority) => {
    if (priority === 1) {
      return 'secondary'
    }
    else if (priority === 2) {
      return 'primary'
    }
    else {
      return 'default'
    }
  };

  const getStatusLabel = (priority) => {
    if (priority === 1) {
      return 'Urgente'
    }
    else if (priority === 2) {
      return 'Pendente' 
    }
    else {
      return 'Concluída'
    }
  };

  const handleStatusChange = (event) => {
    setPriorityToEdit(event.target.value);
  };

  useEffect(() => {
    if (demandToEdit.priority === 1) {
      setPriorityToEdit('b');
    }
    else if (demandToEdit.priority === 2) {
      setPriorityToEdit('a');
    }
    else {
      setPriorityToEdit('c');
    }
    
  }, [demandToEdit]);

  const isAdmin = () => {
    if (userRole === 'admin') return false;
    else return true;
  };

  const handleDetails = (demand) => {
    setDemandDetail(demand);
    setOpenDetails(true);
  };

  return (
    <>
    {Demands &&
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{fontWeight: 'bold'}}>
                  Nome
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                  Telefone
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                  Descrição
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                  Status
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                  Editar
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                  Deletar
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                  Histórico
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Demands.map((Demand) => (
                <TableRow
                  hover
                  key={Demand.id}
                >
                  <TableCell>
                    <Box
                      alignItems="center"
                      display="flex"
                    >
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                        {Demand.personName}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {Demand.personPhone}
                  </TableCell>
                  <TableCell>
                    {Demand.description}
                  </TableCell>
                  <TableCell>
                    {<Chip
                        color={getColor(Demand.priority)}
                        label={getStatusLabel(Demand.priority)}
                        size="small"
                      />}
                  </TableCell>
                  <TableCell>
                    <IconButton 
                      aria-label="expand row"
                      size="small" 
                      onClick={() => handleEdit(Demand)} >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton 
                      disabled={isAdmin()}
                      aria-label="expand row" 
                      size="small" 
                      onClick={() => handleDelete(Demand.id, Demand.personName)} 
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => handleDetails(Demand)} >
                      <InfoIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={arrayLength}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Linhas por página"
      />
      <Dialog
        open={openDetails}
        onClose={handleClose}
        fullWidth
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography
                color="textPrimary"
                variant="h2"
              >
                Histórico da demanda
              </Typography>
            </DialogContentText>
            <div style={{
              display: 'flex',
              justifyContent:'flex-start',
              border: '1px solid #bdbdbd', 
              borderRadius: '10px',
              marginBottom: '20px',
              minHeight: '100px',
              padding: '20px 20px'
            }}>
              {!isEmpty(demandDetail.actions) ? (
                <Timeline>
                  {demandDetail.actions.map((action) => (
                    <TimelineItem>
                      <TimelineOppositeContent style={{ flex: 0.01}} />
                      <TimelineSeparator>
                        <TimelineDot color="primary"/>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                      <Paper elevation={3} className={classes.paper}>
                        <Typography >
                          {action.description}
                        </Typography>
                        <Typography variant="h6" component="h1" style={{color: '#808080'}}>
                          Criado por {action.userName} dia {moment(action.date).format('DD/MM/YYYY HH:ss')}
                        </Typography>
                      </Paper>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              ) : (
                <Typography
                  color="textPrimary"
                  variant="h5"
                >
                  Nenhuma ação cadastrada
                </Typography>
              )}
            </div>
            <ButtonContainer>
              <span>
                <Button 
                  onClick={handleClose} 
                  color="primary"
                  size="small"
                  variant="outlined"
                >
                  Fechar
                </Button>
              </span>
            </ButtonContainer>
          </DialogContent>
          
        </Box>
      </Dialog>
      <Dialog
        open={openDelete}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Box>
            <DialogTitle id="alert-dialog-title">{"Deseja excluir essa demanda?"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Essa ação será irreversível.
              </DialogContentText>
            </DialogContent>
            <ButtonContainer>
              <span>
                <Button 
                  onClick={handleClose} 
                  color="primary"
                  size="small"
                  variant="outlined"
                >
                  Cancelar
                </Button>
              </span>
              <span>
                <Button 
                  onClick={handleDeleteDemand} 
                  color="secondary"
                  size="small"
                  variant="outlined"
                  autoFocus
                >
                  Deletar
                </Button>
              </span>
            </ButtonContainer>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog 
        open={open}
        fullWidth
        maxWidth={"md"}
        onClose={handleClose} 
        aria-labelledby="form-dialog-title">
        <Box >
          <DialogContent>
            <Formik
              initialValues={{
                description: demandToEdit.description,
                local: demandToEdit.local,
              }}
              validationSchema={
                Yup.object().shape({
                  description: Yup.string().max(500).required('Descrição da demanda é obrigatório'),
                  local: Yup.string(),
                })
              }
              onSubmit={(values) => {
                handleEditDemand(values);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box sm={3}>
                    <Typography
                      color="textPrimary"
                      variant="h2"
                    >
                      Editar demanda de {demandToEdit.personName}
                    </Typography>
                  </Box>
                  <TextField
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                    label="Descrição"
                    margin="normal"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    multiline
                    rows={8}
                    variant="outlined"
                    fullWidth
                  />
                  <TopSpacing />
                  <TextField
                    fullWidth
                    label="Local da demanda"
                    margin="normal"
                    name="local"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.local}
                    variant="outlined"
                  />
                  <TopSpacing />
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Status da demanda</FormLabel>
                    <RadioGroup aria-label="priority" name="priority" defaultValue="a">
                      <FormControlLabel 
                        value="a"
                        control={
                          <Radio
                            checked={priorityToEdit === 'a'}
                            onChange={handleStatusChange}
                            value="a"
                            color="primary"
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'A' }}
                          />
                        }
                        label="Pendente"
                      />
                      <FormControlLabel 
                        value="b"
                        control={
                          <Radio
                            checked={priorityToEdit === 'b'}
                            onChange={handleStatusChange}
                            value="b"
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'B' }}
                          />
                        }
                        label="Urgente"
                      />
                      <FormControlLabel 
                        value="c"
                        control={
                          <Radio
                            checked={priorityToEdit === 'c'}
                            onChange={handleStatusChange}
                            value="c"
                            name="radio-button-demo"
                            color="primary"
                            inputProps={{ 'aria-label': 'C' }}
                          />
                        }
                        label="Concluída"
                      />
                    </RadioGroup>
                  </FormControl>
                  <TopSpacing />
                  <TopSpacing />
                    <Divider />
                    <Box style={{marginTop: 10}} sm={6} lg={6} xl={6} xs={12}>
                      <Typography
                        color="textPrimary"
                        variant="h4"
                      >
                        Ações da demanda
                      </Typography>
                    
                    {actionsToEdit && actionsToEdit.map((action, index) => (
                      <Box sm={6} lg={6} xl={6} xs={12}> 
                        <ActionContainer>
                          <TextField
                            id={`action-description-${index}`}
                            fullWidth
                            label="Ação da demanda"
                            margin="normal"
                            name="actionDescription"
                            onBlur={handleBlur}
                            onChange={(event) => handleSetActionDescription(index, event)}
                            value={action.description}
                            variant="outlined"
                            disabled={checkCanEdit(action)}
                          />
                          <Spacing />
                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                            <KeyboardDatePicker
                              fullWidth
                              disableFuture
                              inputVariant="outlined"
                              disableToolbar
                              format="dd/MM/yyyy"
                              margin="normal"
                              id={`date-picker-inline-${index}`}
                              label="Data da ação"
                              value={action.date}
                              onChange={(value) => handleDateChange(value, index)}
                              KeyboardButtonProps={{
                                'aria-label': 'Mudar data',
                              }}
                              disabled={checkCanEdit(action)}
                            />
                          </MuiPickersUtilsProvider>
                          <Spacing />
                          <IconButton 
                            disabled={checkCanEdit(action)} 
                            aria-label="delete" 
                            onClick={() => handleRemoveAction(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </ActionContainer>
                      </Box>
                    ))}
                    </Box>
                    <AddContainer>
                      <IconButton
                        color="primary"
                        fullWidth
                        size="small"
                        variant="contained"
                        onClick={() => handleAddAction()}
                      >
                        <AddCircleIcon color="primary" />
                      </IconButton>
                    </AddContainer>
                  <TopSpacing />
                  <ButtonContainer>
                    <span>
                      <Button
                        color="primary"
                        fullWidth
                        size="small"
                        variant="outlined"
                        onClick={() => setOpen(false)}
                      >
                        Voltar
                      </Button>
                    </span>
                    <span>
                      <Button
                        color="primary"
                        fullWidth
                        size="small"
                        type="submit"
                        variant="contained"
                      >
                        Salvar
                      </Button>
                    </span>
                  </ButtonContainer>
                </form>
              )}
            </Formik>
          </DialogContent>
        </Box>
      </Dialog>
    </Card>
  }
  </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  Demands: PropTypes.array.isRequired
};

export default Results;
