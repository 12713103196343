import {
  takeLatest,
  put,
  call,
} from 'redux-saga/effects';
import { db } from '../../config/firebase';
import moment from 'moment';
import Types from './types';

/************************** AUXILIAR FUNCTIONS **************************/ 

function updateIndexMaintence(value, payload){
  if (payload.maintenceType === 'HR'){
    return value - (payload.lastMaintence + payload.maintencePeriod);
  } else {
    return value - (payload.lastMaintence + payload.maintencePeriod);
  }
}

function updateIndexMaintencePiece(focusValue, payload){
  if (payload) {
    if (payload[0].name === undefined) {
      const newArray = [];
      return newArray;
    } else {
      const newArray = payload.map((categorie) => {
        return {
          ...categorie, 
          indexMaintence: focusValue - (categorie.lastMaintence + categorie.maintencePeriod)
        };
      })
      return newArray;
    }
  } else {
    const newArray = [];
    return newArray;
  }
}

/************************** GET PERSON **************************/ 
async function dbGetPersonList(payload) {
  const { officeId } = payload;
  const response = await db.collection('gabinete').doc(officeId).collection('person')
  .orderBy('name')
  .get().then(query => {
    const data = query.docs.map(doc => doc.data());
    return data;
  })
  .catch(function(error){
    console.error("Error adding document: ", error);
  })
  return response;
}

function* getPersonList({ payload }) {
  const data = yield call(dbGetPersonList, payload);
  yield put({
    type: Types.LOAD_MACHINE_LIST_COMPLETED,
    payload: {
      personList: data,
    },
  });
}

/************************** GET PERSON FILTERED **************************/ 
async function dbGetPersonListFiltered(payload) {
  const { officeId, searchText } = payload;
  const response = await db.collection('gabinete').doc(officeId).collection('person')
  .where('name', '>=', searchText).where('name', '<=', searchText+ '\uf8ff')
  .orderBy('name')
  .get().then(query => {
    const data = query.docs.map(doc => doc.data());
    return data;
  })
  .catch(function(error){
    console.error("Error adding document: ", error);
  });
  if (response) return response;
  return [];
}

function* getPersonListFiltered({ payload }) {
  try {
    const data = yield call(dbGetPersonListFiltered, payload);
    yield put({
      type: Types.LOAD_PERSON_FILTERED_LIST_COMPLETED,
      payload: {
        personList: data,
      },
    });
  } catch (error) {
    yield put({
      type: Types.LOAD_PERSON_FILTERED_LIST_ERROR,
      payload: {
        personList: [],
      },
    });
  }
  
}

/************************** GET DEMANDS **************************/ 
async function dbGetDemandsList(payload) {
  const { officeId } = payload;
  const response = await db.collection('gabinete').doc(officeId).collection('demands')
  .orderBy('priority', 'asc')
  .get().then(query => {
    const data = query.docs.map(doc => doc.data());
    return data;
  })
  .catch(function(error){
    console.error("Error adding document: ", error);
  })
  return response;
}

function* getDemandsList({ payload }) {
  const data = yield call(dbGetDemandsList, payload);
  yield put({
    type: Types.LOAD_DEMAND_LIST_COMPLETED,
    payload: {
      demandList: data,
    },
  });
}

/************************** GET DEMANDS FILTERED **************************/ 
async function dbGetDemandsListFiltered(payload) {
  const { officeId, searchText } = payload;
  const response = await db.collection('gabinete').doc(officeId).collection('demands')
  .where('personName', '>=', searchText).where('personName', '<=', searchText+ '\uf8ff')
  .get().then(query => {
    const data = query.docs.map(doc => doc.data());
    return data;
  })
  .catch(function(error){
    console.error("Error adding document: ", error);
  });
  if (response) return response;
  return [];
}

function* getDemandsListFiltered({ payload }) {
  try {
    const data = yield call(dbGetDemandsListFiltered, payload);
    yield put({
      type: Types.LOAD_DEMAND_FILTERED_LIST_COMPLETED,
      payload: {
        demandList: data,
      },
    });
  } catch (error) {
    yield put({
      type: Types.LOAD_DEMAND_FILTERED_LIST_ERROR,
      payload: {
        demandList: [],
      },
    });
  }
}

/************************** GET USERS **************************/ 
async function dbGetUserList(payload) {
  const { officeId } = payload;
  const response = await db.collection('gabinete').doc(officeId).collection('users')
  .get().then(query => {
    const data = query.docs.map(doc => doc.data());
    return data;
  })
  .catch(function(error){
    console.error("Error adding document: ", error);
  })
  return response;
}

function* getUserList({ payload }) {
  const data = yield call(dbGetUserList, payload);
  yield put({
    type: Types.LOAD_USER_LIST_COMPLETED,
    payload: {
      userList: data,
    },
  });
}

/************************** CREATE USER **************************/ 
async function dbCreateUser(payload) {
  const { officeId } = payload;
  await db.collection('gabinete').doc(officeId).collection('users').doc(payload.userId).add(payload);
}

function* handleCreateUser({ payload }) {
  const data = yield call(dbCreateUser, payload);
  yield put({
    type: Types.CREATE_USER_COMPLETED,
    payload: {
      user: data,
    },
  });
}

/************************** CREATE PERSON **************************/ 
async function dbCreatePerson(payload) {
  const { 
    name, 
    email, 
    phone, 
    birthdate, 
    street, 
    district, 
    city,
    voterRegistration,
    zone,
    section,
    officeId,
  } = payload;

  const ref = db.collection('gabinete').doc(officeId).collection('person').doc();
  const myId = ref.id;
  await db.collection('gabinete').doc(officeId).collection('person').doc(myId).set({
    id: myId,
    name, email, phone, birthdate, street, district, city,
    voterRegistration, zone, section,
    createdAt: moment().format()
  })
  .catch(function(error){
    console.error("Error adding document: ", error);
  })
}

function* handleCreatePerson({ payload }) {
  yield call(dbCreatePerson, payload);
  const officePayload = { officeId: payload.officeId };
  yield call(dbGetPersonList, officePayload);
}

/************************** CREATE DEMAND **************************/ 
async function dbCreateDemand(payload) {
  const { 
    description, 
    personName, 
    personPhone, 
    personId, 
    priority, 
    local, 
    officeId, 
    concludedDate, 
    actions 
  } = payload;

  const ref = db.collection('gabinete').doc(officeId).collection('demands').doc();
  const myId = ref.id;
  await db.collection('gabinete').doc(officeId).collection('demands').doc(myId).set({
    id: myId,
    description, 
    personName, 
    personId, 
    priority, 
    personPhone, 
    local, 
    concludedDate, 
    actions,
    createdAt: moment().format()
  })
  .catch(function(error){
    console.error("Error adding document: ", error);
  })
}

function* handleCreateDemand({ payload }) {
  yield call(dbCreateDemand, payload);
  const officePayload = { officeId: payload.officeId };
  yield call(dbGetDemandsList, officePayload);
}

/************************** GET AMOUNT MAINTENCE **************************/ 
async function dbGetTotalMaintence() {
  const response = await db.collection('maintences').get().then(query => {
    const data = query.docs.map(doc => doc.get('maintenceValue'));
    return data;
  })
  .catch(function(error){
    console.error("Error adding document: ", error);
  })
  const total = response.reduce(function(total, numero){
    return total + numero;
  }, 0);
  return total;
}

function* getTotalMaintence() {
  const data = yield call(dbGetTotalMaintence);
  yield put({
    type: Types.LOAD_TOTAL_MAINTENCE_COST_COMPLETED,
    payload: {
      totalOfMaintence: data,
    },
  });
}

/************************** CREATE DAILY **************************/ 
async function dbCreateDaily(payload) {
  const { categories, values } = payload;
  await db.collection('machines').doc(values.machineId).collection('dailyControl').add(values);
  if (values.maintenceType === 'HR') {
    if (values.valueInitial > values.hourMeterCurrent) {
      const focusValue = values.valueInitial + values.valueDif;
      await db.collection('machines').doc(values.machineId).set({
        hourMeterCurrent: focusValue,
        indexMaintence: updateIndexMaintence(focusValue, values),
        maintenceCategories: updateIndexMaintencePiece(focusValue, categories)
      }, { merge: true });
    } else {
      const focusValue = values.hourMeterCurrent + values.valueDif;
      await db.collection('machines').doc(values.machineId).set({
        hourMeterCurrent: focusValue,
        indexMaintence: updateIndexMaintence(focusValue, values),
        maintenceCategories: updateIndexMaintencePiece(focusValue, categories)
      }, { merge: true });
    }
  }
  if (values.maintenceType === 'KM') {
    if (values.valueInitial > values.miliageCurrent) {
      const focusValue = values.valueInitial + values.valueDif;
      await db.collection('machines').doc(values.machineId).set({
        miliageCurrent: focusValue,
        indexMaintence: updateIndexMaintence(focusValue, values),
        maintenceCategories: updateIndexMaintencePiece(focusValue, categories)
      }, { merge: true });
    } else {
      const focusValue = values.miliageCurrent + values.valueDif;
      await db.collection('machines').doc(values.machineId).set({
        miliageCurrent: focusValue,
        indexMaintence: updateIndexMaintence(focusValue, values),
        maintenceCategories: updateIndexMaintencePiece(focusValue, categories)
      }, { merge: true });
    }
  }
}

function* handleCreateDaily({ payload }) {
  yield call(dbCreateDaily, payload);
  //yield call(dbGetMachineList);
}

/************************** CREATE MAINTENCE **************************/ 
async function dbCreateMaintence(payload) {
  const { values, categoriesToUpdate, isGeneral } = payload;
  await db.collection('maintences').add(values);
  if (isGeneral) {
    await db.collection('machines').doc(values.machineId).set({
      lastMaintence: values.miliageHour,
      indexMaintence: values.maintencePeriod * (-1),
      maintenceCategories: categoriesToUpdate
    }, { merge: true });
  } else {
    await db.collection('machines').doc(values.machineId).set({
      maintenceCategories: categoriesToUpdate
    }, { merge: true });
  };
}

function* handleCreateMaintence({ payload }) {
  yield call(dbCreateMaintence, payload);
  //yield call(dbGetMachineList);
}

/************************** GET SERVICES **************************/ 
async function dbGetServiceList() {
  const response = await db.collection('services')
  .orderBy('createdAt', 'desc')
  .get().then(query => {
    const data = query.docs.map(doc => doc.data());
    return data;
  })
  .catch(function(error){
    console.error("Error adding document: ", error);
  })
  return response;
}

function* getServiceList() {
  const data = yield call(dbGetServiceList);
  yield put({
    type: Types.LOAD_SERVICE_LIST_COMPLETED,
    payload: {
      serviceList: data,
    },
  });
}

/************************** CREATE SERVICE **************************/

async function dbCreateService(payload) {
  const { name, company, local, initialDate, finalDate } = payload;
  const ref = db.collection('services').doc();
  const myId = ref.id;
  if (finalDate === null) {
    await db.collection('services').doc(myId).set({
      id: myId,
      createdAt: moment().format(),
      name,
      company,
      local,
      initialDate,
      hourMeterTotal: 0,
      miliageTotal: 0,
      active: true,
    });
  } else {
    await db.collection('services').doc(myId).set({
      id: myId,
      createdAt: moment().format(),
      name,
      company,
      local,
      initialDate,
      hourMeterTotal: 0,
      miliageTotal: 0,
      active: false,
      finalDate
    });
  }
  
}

function* handleCreateService({ payload }) {
  yield call(dbCreateService, payload);
  yield call(dbGetServiceList);
}

function* handleDeleteService({ payload }) {
  db.collection('services').doc(payload).delete();
  yield call(getServiceList);
}

async function handleDailyControlIntoService({ payload }) {
  const {
    createdAt,
    serviceId,
    machineId,
    machineName,
    valueDif,
    fillLiters,
    maintenceType,
    hourMeterTotal,
    miliageTotal
  } = payload
  const ref = db.collection('services').doc(serviceId).collection('reports').doc();
  const myId = ref.id;
  await db.collection('services').doc(serviceId).collection('reports').doc(myId).set({
    id: myId,
    serviceId,
    machineId,
    machineName,
    valueDif,
    fillLiters,
    maintenceType,
    createdAt
  });
  if (maintenceType === 'HR') {
    await db.collection('services').doc(serviceId).set({
      hourMeterTotal: hourMeterTotal + valueDif,
    }, { merge: true });
  }
  if (maintenceType === 'KM') {
    await db.collection('services').doc(serviceId).set({
      miliageTotal: miliageTotal + valueDif,
    }, { merge: true });
  }
}

async function dbGetReportsIntoService(payload) {
  const response = await db.collection('services').doc(payload).collection('reports')
  .get().then(query => {
    const data = query.docs.map(doc => doc.data());
    return data;
  })
  .catch(function(error){
    console.error("Error adding document: ", error);
  })
  return response
}

function* getReportsIntoService({ payload }) {
  const data = yield call(dbGetReportsIntoService, payload);
  yield put({
    type: Types.LOAD_REPORT_INTO_SERVICE_COMPLETED,
    payload: {
      reportsIntoService: data,
    },
  });
}

export function* pmpSagas() {
  yield takeLatest(Types.CREATE_PERSON, handleCreatePerson);
  yield takeLatest(Types.CREATE_DEMAND, handleCreateDemand);
  yield takeLatest(Types.LOAD_MACHINE_LIST, getPersonList);
  yield takeLatest(Types.LOAD_PERSON_FILTERED_LIST, getPersonListFiltered);
  yield takeLatest(Types.LOAD_DEMAND_LIST, getDemandsList);
  yield takeLatest(Types.LOAD_DEMAND_FILTERED_LIST, getDemandsListFiltered);
  yield takeLatest(Types.LOAD_TOTAL_MAINTENCE_COST, getTotalMaintence);
  yield takeLatest(Types.CREATE_DAILY, handleCreateDaily);
  yield takeLatest(Types.CREATE_MAINTENCE, handleCreateMaintence);
  yield takeLatest(Types.CREATE_USER, handleCreateUser);
  yield takeLatest(Types.LOAD_USER_LIST, getUserList);
  yield takeLatest(Types.CREATE_SERVICE, handleCreateService);
  yield takeLatest(Types.LOAD_SERVICE_LIST, getServiceList);
  yield takeLatest(Types.DELETE_SERVICE, handleDeleteService);
  yield takeLatest(Types.CREATE_SERVICE_REPORT, handleDailyControlIntoService);
  yield takeLatest(Types.LOAD_REPORT_INTO_SERVICE, getReportsIntoService);
}