import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import ReactApexCharts from 'react-apexcharts'
import {
  Card,
  Typography,
  CardContent,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {height: '100%'},
}));

const ConcludedDemands = ({ className, demands, ...rest }) => {
  const classes = useStyles();
  const [series, setSeries] = useState([
    {name: 'categoria1', data: [0,0,0,0,0,0,0,0,0,0,0,0]}
  ]);
  const [loading, setLoading] = useState(true);
  const year = moment().year();

  const fitOnMonth = (element, index) => {
    const month = parseInt(element.concludedDate.substr(5, 2));
    const _year = parseInt(element.concludedDate.substr(0, 4));
    if (year === _year) {
      const newValue = series[0].data[month-1] + 1;
      const { name, data } = series[0];
      let newArray = data;
      newArray[month-1] = newValue;
      setSeries([{name, data: newArray}]);
    }
  }

  async function fetchData(demands) {
    await demands.forEach(fitOnMonth);
    setLoading(false);
  }

  const options = {
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: ['Jan','Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    },
    yaxis: {
      title: {
        text: 'Demandas concluídas',
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " demanda(s)"
        }
      }
    }
  };

  useEffect(() => {
    fetchData(demands);
  }, [demands]);

  return (
    <>
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        { loading ? (
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <CircularProgress /> 
          </div>
          ) : (
          <>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Typography
                  color="textPrimary"
                  variant="h4"
                >
                  Demandas concluídas em {year}
                </Typography>
            </div>
            <ReactApexCharts options={options} series={series} type="bar" height={350} />
        </>
        )}
      </CardContent>
    </Card>
  </>
  );
};

ConcludedDemands.propTypes = {
  className: PropTypes.string,
  demands: PropTypes.any.isRequired,
};

export default ConcludedDemands;
