import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Creators } from '../../../store/pmp/actions';
import { toUpper } from 'lodash';
import { useDispatch } from 'react-redux';
import { Search as SearchIcon } from 'react-feather';
import { TopSpacing, FilterContainer, Spacing } from './styles';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState();

  const handleSearchText = (event) => {
    setSearchText(event.target.value);
  };

  const handleSearch = () => {
    const officeId = sessionStorage.getItem('office-id');
    const payload = { searchText: toUpper(searchText), officeId };
    dispatch(Creators.getPersonsFiltered(payload));
  };

  const handleResetSearch = () => {
    const officeId = sessionStorage.getItem('office-id');
    const payload = { officeId };
    setSearchText('');
    dispatch(Creators.getPersons(payload));
  };

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
      >
        <Button
          color="primary"
          variant="contained"
          onClick={() => navigate('/app/pessoas/adicionar')}
        >
          Adicionar pessoa
        </Button>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              Filtros
            </Typography>
            <TopSpacing />
            <FilterContainer>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                onChange={(e) => handleSearchText(e)}
                placeholder="Procurar nome da pessoa"
                variant="outlined"
              />
              <Spacing />
              <Button
                color="primary"
                variant="contained"
                size="small"
                style={{height: 30}}
                onClick={() => handleSearch()}
              >
                Filtrar
              </Button>
              <Spacing />
              <Button
                color="primary"
                variant="outlined"
                size="small"
                style={{height: 30}}
                onClick={() => handleResetSearch()}
              >
                Limpar
              </Button>
            </FilterContainer>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
