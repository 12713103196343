import styled from 'styled-components'

export const LoadingContainer = styled.div`
  padding: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const NumericContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const Spacing = styled.div`
  margin-left: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  span: {
    width: 140px;
  }
`;