import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Box,
  Grid,
  Container,
  makeStyles,
  Card, 
  Button,
  Typography,
  TextField,
  Divider,
  Snackbar,
  CircularProgress,
  IconButton,
  FormLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from "date-fns/locale";
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../context/authContext';
import { Creators } from '../../../store/pmp/actions';
import Page from '../../../../src/components/Page';
import Alert from '../../../../src/components/Alert';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { FormContainer, Spacing, TopSpacing, ButtonContainer, AddContainer, ActionContainer } from './styles';

const useStyles = makeStyles((theme, rest) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  card: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  formControl: {
    marginTop: 2,
    minWidth: 300,
  },
}));

const AddDemandView = () => {
  const { currentUser, officeId } = useAuth();
  const [selectedPerson, setSelectedPerson] = useState();
  const [priorityToEdit, setPriorityToEdit] = useState('a');
  const [personId] = useState();
  const userName = sessionStorage.getItem('user-name');
  const [actions, setActions] = useState([{
    description: '',
    date: moment().format(),
    userName
  }]);
  const [selectedDate, setSelectedDate] = useState(moment().format());
  const { personList, loading } = useSelector((state) => {
    return state.pmpReducer;
  });
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    const userId = currentUser.uid;
    const payload = { userId, officeId };
    dispatch(Creators.getPersons(payload));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStatusChange = (event) => {
    setPriorityToEdit(event.target.value);
  };

  const handleCreateDemand = (values, { resetForm }) => {
    console.log(actions);
    const { description, local } = values;
    const userId = currentUser.uid;
    let priority;
    let concludedDate = '';
    if (priorityToEdit === 'b') {
      priority = 1;
    }
    else if (priorityToEdit === 'a') {
      priority = 2;
    }
    else {
      priority = 3;
      concludedDate = moment().format();
    }
    const payload = {
      description,
      local,
      personName: selectedPerson.name,
      personPhone: selectedPerson.phone,
      personId: selectedPerson.id,
      priority,
      userId,
      officeId,
      concludedDate,
      actions
    };
    dispatch(Creators.createDemand(payload));
    setOpen(true);
    resetForm({});
  };

  const [open, setOpen] = useState(false);
  const [openError,] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const selectItem = (person, setFieldValue) => {
    setSelectedPerson(person);
  }; 

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleAddAction= () => {
    const transformed = [...actions];

    transformed.push({
      description: '',
      date: moment().format(),
      userName
    });

    setActions(transformed);
  };

  const handleSetActionDescription = (index, event) => {
    console.log(event.target.value);
    const newActions = actions.map((action,sIndex) => {
      console.log(sIndex === index);
      if (index !== sIndex) return action;
      return { 
        ...action, 
        description: event.target.value,
      };
    })
    setActions(newActions);
  };

  const handleRemoveAction = (index) => {
    setActions(
      actions.filter((s, sindex) => index !== sindex)
    )
  };

  return (
    <Page
      className={classes.root}
      title="Adicionar demandas"
    >
      <Container maxWidth={false}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Demanda cadastrada com sucesso!
        </Alert>
      </Snackbar>
      <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Erro ao cadastrar demanda.
        </Alert>
      </Snackbar>
        <Box mt={3}>
          <Card >
            <FormContainer>
              <Formik
                initialValues={{
                  description: '',
                  local: '',
                }}
                validationSchema={
                  Yup.object().shape({
                    description: Yup.string().max(1000).required('Descrição da demanda é obrigatório'),
                    local: Yup.string().max(100),
                  })
                }
                onSubmit={(values, resetForm) => {
                  handleCreateDemand(values, resetForm);
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  resetForm,
                  setFieldValue,
                  touched,
                  values
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box sm={3}>
                      <Typography
                        color="textPrimary"
                        variant="h2"
                      >
                        Adicionar nova demanda
                      </Typography>
                    </Box>
                    <TopSpacing />
                    { loading ? <CircularProgress /> :
                      <Grid item lg={6} sm={6} xl={6} xs={12}>
                        <Autocomplete 
                          id="combo-box-demo"
                          value={personId}
                            onChange={(event, newValue) => {
                              selectItem(newValue);
                          }}
                          options={personList}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => <TextField {...params} label="Selecione a pessoa" variant="outlined" />}
                        />
                      </Grid>
                    }
                    <TextField
                      label="Descrição"
                      margin="normal"
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      variant="outlined"
                      multiline
                      disabled={!selectedPerson}
                      rows={4}
                      fullWidth
                    />
                    <TextField
                      fullWidth
                      label="Local da demanda"
                      margin="normal"
                      name="local"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.local}
                      variant="outlined"
                    />
                    <TopSpacing />
                    {/* <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                      <Typography
                        color="textPrimary"
                        variant="subtitle1"
                      >
                        Demanda Urgente
                      </Typography>
                      <Switch
                        checked={isUrgent}
                        onChange={(event) => setIsUrgent(event.target.checked)}
                        color="primary"
                        name="checkedB"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </div> */}
                    <Spacing />
                    <FormControl component="fieldset">
                    <FormLabel component="legend">Status da demanda</FormLabel>
                    <RadioGroup aria-label="priority" name="priority" defaultValue="a">
                      <FormControlLabel 
                        value="a"
                        control={
                          <Radio
                            checked={priorityToEdit === 'a'}
                            onChange={handleStatusChange}
                            value="a"
                            color="primary"
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'A' }}
                          />
                        }
                        label="Pendente"
                      />
                      <FormControlLabel 
                        value="b"
                        control={
                          <Radio
                            checked={priorityToEdit === 'b'}
                            onChange={handleStatusChange}
                            value="b"
                            name="radio-button-demo"
                            inputProps={{ 'aria-label': 'B' }}
                          />
                        }
                        label="Urgente"
                      />
                      <FormControlLabel 
                        value="c"
                        control={
                          <Radio
                            checked={priorityToEdit === 'c'}
                            onChange={handleStatusChange}
                            value="c"
                            name="radio-button-demo"
                            color="primary"
                            inputProps={{ 'aria-label': 'C' }}
                          />
                        }
                        label="Concluída"
                      />
                    </RadioGroup>
                    </FormControl>
                    <TopSpacing />
                    <Divider />
                    <Box style={{marginTop: 10}} sm={6} lg={6} xl={6} xs={12}>
                      <Typography
                        color="textPrimary"
                        variant="h4"
                      >
                        Ações da demanda
                      </Typography>
                    
                    {actions.map((action, index) => (
                      <Box sm={6} lg={6} xl={6} xs={12}> 
                        <ActionContainer>
                          <TextField
                            fullWidth
                            label="Ação da demanda"
                            margin="normal"
                            name="actionDescription"
                            onBlur={handleBlur}
                            onChange={(event) => handleSetActionDescription(index, event)}
                            value={action.description}
                            variant="outlined"
                          />
                          <Spacing />
                          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                            <KeyboardDatePicker
                              fullWidth
                              disableFuture
                              disabled
                              inputVariant="outlined"
                              disableToolbar
                              format="dd/MM/yyyy"
                              margin="normal"
                              id="date-picker-inline"
                              label="Data da ação"
                              value={selectedDate}
                              onChange={handleDateChange}
                              KeyboardButtonProps={{
                                'aria-label': 'Mudar data',
                              }}
                            />
                          </MuiPickersUtilsProvider>
                          <Spacing />
                          <IconButton aria-label="delete" onClick={() => handleRemoveAction(index)}>
                            <DeleteIcon />
                          </IconButton>
                        </ActionContainer>
                      </Box>
                    ))}
                    </Box>
                    <AddContainer>
                      <IconButton
                        color="primary"
                        fullWidth
                        size="small"
                        variant="contained"
                        onClick={() => handleAddAction()}
                      >
                        <AddCircleIcon color="primary" />
                      </IconButton>
                    </AddContainer>
                  
                    <TopSpacing />
                    <Divider />
                    <TopSpacing />
                    <ButtonContainer>
                      <span>
                        <Button
                          color="primary"
                          fullWidth
                          size="large"
                          variant="outlined"
                          onClick={() => navigate('/app/demandas')}
                        >
                          Voltar
                        </Button>
                      </span>
                      <span>
                        <Button
                          color="primary"
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                          disabled={!selectedPerson}
                        >
                          Adicionar
                        </Button>
                      </span>
                    </ButtonContainer>
                  </form>
                )}
              </Formik>
            </FormContainer>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default AddDemandView;
