import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useAuth } from '../../context/authContext';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useSelector } from 'react-redux';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import SimpleDialog from './NotificationDialog';
import Logo from '../../../src/components/Logo';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  }
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();
  const { machineList } = useSelector((state) => {
    return state.pmpReducer;
  });
  const [notifications, setNotifications] = useState([1,2,3]);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleLogout = () => {
    const returnLogout = logout();
    navigate('/login', { replace: true });
  }

  const handleNotifications = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const isLateMaintence = (value) => {
    return value.indexMaintence > 0;
  }

  /* useEffect(() => {
    const filtered = machineList.filter(isLateMaintence);
    setNotifications(filtered);
  }, [machineList]); */

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/app/central">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <SimpleDialog selectedValue={notifications} open={open} onClose={handleClose} />
        <Hidden mdDown>
          {/* <IconButton onClick={() => handleNotifications()} color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="secondary"
              variant="dot"
            >
              {/* <NotificationsIcon /> 
            </Badge>
          </IconButton> */}
          <IconButton color="inherit" onClick={handleLogout}>
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
