import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import moment from 'moment';
import Page from '../../../../src/components/Page';
import Budget from './Budget';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth } from '../../../context/authContext';
import { Creators } from '../../../store/pmp/actions';
import TasksProgress from './TasksProgress';
import TotalCustomers from './TotalCustomers';
import BirthList from './BirthList';
import ConcludedDemands from './ConcludedDemands';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = () => {
  const { currentUser, officeId } = useAuth();
  const { personList, demandList, loading } = useSelector((state) => {
    return state.pmpReducer;
  });
  const dispatch = useDispatch();
  const [concludedDemands, setConcludedDemands] = useState([]);
  const [, setUrgentDemands] = useState([]);
  const [birthdates, setBirthdates] = useState([]);
  const classes = useStyles();

  const searchCompletedDemands = (value) => {
    return value.priority === 3;
  }

  const searchUrgentDemands = (value) => {
    return value.priority === 1;
  }

  const searchBirths = (value) => {
    if (value.birthdate !== undefined) {
      const dateValue = value.birthdate;
      const today = moment().format('DD/MM/YYYY');
      const arrayToday = today.split('/');
      const formatedBirth = dateValue.split('/');
      const birthMonth = arrayToday[1] === formatedBirth[1];
      const birthDay = arrayToday[0] === formatedBirth[0];
      return birthDay && birthMonth
    }
  }

  useEffect(() => {
    const payload = { userId: currentUser.uid, officeId };
    dispatch(Creators.getPersons(payload));
    dispatch(Creators.getDemands(payload));
    dispatch(Creators.getUsers(payload));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const arr = demandList.filter(searchCompletedDemands);
    setConcludedDemands(arr);
    const urgents = demandList.filter(searchUrgentDemands);
    setUrgentDemands(urgents);
  }, [demandList]);

  useEffect(() => {
    const births = personList.filter(searchBirths);
    setBirthdates(births);
  }, [personList]);

  return (
    <Page
      className={classes.root}
      title="Central de atividades"
    >
      <Container maxWidth={false}>
        { loading ? <CircularProgress /> : 
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            sm={4}
            xl={4}
            xs={12}
          >
            <Budget numberOfMachines={personList.length}/>
          </Grid>
          <Grid
            item
            lg={4}
            sm={4}
            xl={4}
            xs={12}
          >
            <TotalCustomers totalValue={demandList.length} />
          </Grid>
          <Grid
            item
            lg={4}
            sm={4}
            xl={4}
            xs={12}
          >
            <TasksProgress numberOfPieces={concludedDemands.length}/>
          </Grid>
          {/*<Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <TotalProfit servicesActive={10}/>
          </Grid> */}
          <Grid
            item
            lg={'12'}
            md={'12'}
            xl={'12'}
            xs={'12'}
          >
           {loading ?
              (
                <CircularProgress />
              ) :
              ( 
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    lg={'8'}
                    md={'8'}
                    xl={'8'}
                    xs={'12'}
                  >
                    <ConcludedDemands demands={concludedDemands}/>
                  </Grid>
                  <Grid
                    item
                    lg={'4'}
                    md={'4'}
                    xl={'4'}
                    xs={'12'}
                  >
                    <BirthList births={birthdates} />
                  </Grid>
                </Grid>
              )
             }
            
          </Grid>
        </Grid>
        }
      </Container>
    </Page>
  );
};

export default Dashboard;
