import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { db, auth } from '../../../config/firebase';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Snackbar,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import Alert from '../../../components/Alert';

const useStyles = makeStyles(() => ({
  root: {}
}));

const ProfileDetails = ({ className, ...rest }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = auth.currentUser
  const [values, setValues] = useState({
    displayName: user.displayName,
    email: user.email,
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleUpdateProfile = async () => {
    setLoading(true);
    await user.updateProfile({
      displayName: values.displayName
    })
    .then(
      setOpen(true)
    )
    setLoading(false);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Perfil atualizado com sucesso!
          </Alert>
        </Snackbar>
        <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            Erro ao atualizar perfil.
          </Alert>
        </Snackbar>
        <CardHeader
          subheader="Este perfil pode ser editado"
          title="Perfil"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Por favor, defina seu nome"
                label="Nome"
                name="displayName"
                onChange={handleChange}
                required
                value={values.displayName}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email"
                name="email"
                onChange={handleChange}
                required
                disabled
                value={values.email}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleUpdateProfile}
            disabled={loading}
            type="submit"
          >
            {loading ? <CircularProgress /> : 'Salvar Perfil'}
          </Button>
        </Box>
      </Card>
    </form>
  );
};

ProfileDetails.propTypes = {
  className: PropTypes.string
};

export default ProfileDetails;
