import styled from 'styled-components'

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  span: {
    width: 140px;
  }
`;