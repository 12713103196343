import React from 'react';
import logo from '../assets/logoGObranca.png'

const Logo = (props) => {
  return (
    <div style={{width: 50, height: 50, borderRadius: 20, alignContent: 'center',  alignItems: 'center', justifyContent: 'center'}} >
      <img
        alt="Logo"
        src={logo}
        style={{display: 'flex', width: '80%', alignItems: 'center', justifyContent: 'center', marginLeft: 5, marginTop: 10}}
        {...props}
      />
    </div>
  );
};

export default Logo;
