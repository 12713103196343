import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useAuth } from '../../context/authContext';
import {
  Box,
  Button,
  Container,
  TextField,
  Snackbar,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import { db } from '../../config/firebase';
import Page from '../../../src/components/Page';
import Alert from '../../../src/components/Alert';
import logo from '../../assets/logoGO2.png';
import { auth } from '../../config/firebase';
import { ButtonContainer } from './styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  image: {
    width: 300,
    alignSelf: 'center',
    marginBottom: 50
  }

}));
const SECRET_CODE_ERROR = 'Código do gabinete inválido';
const EMAIL_SENT_MSG = 'E-mail de verificação enviado com sucesso.';

const LoginView = () => {
  const classes = useStyles();
  const { login, logout } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openCodeError, setOpenCodeError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openResetPassword, setOpenResetPassword] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };

  const handleLogin = async ({email, password, secretCode}) => {
    setLoading(true);
    try {
      await login(email, password);
      const officeData = await db.collection("gabinete").where("secretCode", "==", secretCode)
      .get()
      .then(query => {
        const data = query.docs.map(doc => doc.data());
        return data;
      })
      .catch(function(error){
        console.error("Error adding document: ", error);
      });

      if (!isEmpty(officeData)) {
        const id = officeData[0].id;
        sessionStorage.setItem('office-id', id);
        setLoading(false);
        navigate('/app/central', { replace: true });
      } else {
        logout()
        setOpenCodeError(true);
        setLoading(false);
      }
    } catch (error) {
      setOpenError(true);
      setLoading(false);
    }
  };

  const handleResetPassword = () => {
    setOpenResetPassword(true);
  };

  const handleSentEmail = ({ emailReset }) => {
    auth.sendPasswordResetEmail(emailReset).then(function() {
      setOpenResetPassword(false);
      setOpenSuccess(true);
    }).catch(function(error) {
      // An error happened.
    });
  }

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <img className={classes.image} src={logo}></img>
        <Container maxWidth="sm">
        <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            Email ou senha inválidos.
          </Alert>
        </Snackbar>
        <Snackbar open={openSuccess} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            {EMAIL_SENT_MSG}
          </Alert>
        </Snackbar>
        <Snackbar open={openCodeError} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            {SECRET_CODE_ERROR}
          </Alert>
        </Snackbar>
          <Formik
            initialValues={{
              email: '',
              password: '',
              secretCode: '',
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Email inválido').max(255).required('Email é obrigatório'),
              password: Yup.string().max(255).required('Senha é obrigatório'),
              secretCode: Yup.string().required('Código do gabinete é obrigatório'),
            })}
            onSubmit={(values) => {
              handleLogin(values)
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Entrar
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                  </Typography>
                </Box>
                <Box
                  mt={3}
                  mb={1}
                >
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Senha"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.secretCode && errors.secretCode)}
                  fullWidth
                  helperText={touched.secretCode && errors.secretCode}
                  label="Código do gabinete"
                  margin="normal"
                  name="secretCode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.secretCode}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={loading}
                  >
                    {loading ? <CircularProgress /> : 'ENTRAR'}
                  </Button>
                </Box>
                
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Esqueceu a senha?
                  {' '}
                  <Button onClick={() => handleResetPassword()} href="#text-buttons" color="primary">
                    Clique aqui
                  </Button>
                </Typography> 
              </form>
            )}
          </Formik>
        </Container>
      </Box>
      <Dialog
        fullWidth
        open={openResetPassword} 
        onClose={handleClose} 
        aria-labelledby="form-dialog-title"
      >
        <Box width={600}>
          <DialogTitle id="alert -dialog-title">
            <Typography
              color="textPrimary"
              variant="h2"
            >
              Redefinir senha
            </Typography>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <ButtonContainer>
              <Typography
                color="textPrimary"
                variant="h6"
              >
                Enviaremos um e-mail para redefinição de sua senha.
              </Typography>

            </ButtonContainer>
            <Formik
              initialValues={{
                emailReset: '',
              
              }}
              validationSchema={
                Yup.object().shape({
                })
              }
              onSubmit={(values) => {
                handleSentEmail(values);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <TextField
                    error={Boolean(touched.emailReset && errors.emailReset)}
                    fullWidth
                    helperText={touched.email && errors.emailReset}
                    label="Email"
                    margin="normal"
                    name="emailReset"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.emailReset}
                    variant="outlined"
                  />
                  <ButtonContainer>
                    <span>
                      <Button
                        color="primary"
                        fullWidth
                        size="small"
                        variant="outlined"
                        onClick={() => setOpenResetPassword(false)}
                      >
                        Voltar
                      </Button>
                    </span>
                    <span>
                      <Button
                        color="primary" 
                        variant="contained"
                        type="submit"
                        size="small"
                      >
                        Enviar
                      </Button>
                    </span>
                  </ButtonContainer>
                </form>
              )}
            </Formik>
            
          </DialogContent>
        </Box>
      </Dialog>
    </Page>
  );
};

export default LoginView;
