import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles,
  CircularProgress
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { useAuth } from '../../../context/authContext';
import { Creators } from '../../../store/pmp/actions';
import Page from '../../../../src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { LoadingContainer } from './styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const MachineListView = () => {
  const { personList, loading } = useSelector((state) => {
    return state.pmpReducer;
  });
  const { currentUser, userRole } = useAuth();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [array, setArray] = useState(personList);

  useEffect(()  => {
    const userId = currentUser.uid;
    const officeId = sessionStorage.getItem('office-id');
    const payload = { userId, officeId };
    dispatch(Creators.getPersons(payload));
    setLimit(10);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const paginate = (array, page_size, page_number) => {
    if (array) {
      return array.slice(page_number * page_size, page_number * page_size + page_size);
    }
    return [];
  };

  useEffect(() => {
    const arr = paginate(personList, limit, page);
    setArray(arr);
  },[page, limit]);

  useEffect(() => {
    const arr = paginate(personList, limit, page);
    setArray(arr);
  }, [personList])

  return (
    <Page
      className={classes.root}
      title="Pessoas"
    >
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          {loading ? 
            (
              <LoadingContainer>
                <CircularProgress />
              </LoadingContainer>
            )
            :
            (array && 
              <Results 
                Persons={array} 
                userId={currentUser.uid} 
                userRole={userRole}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                arrayLength={personList.length}
              />
            )
          }
        </Box>
      </Container>
    </Page>
  );
};

export default MachineListView;
