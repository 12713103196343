import React, { useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Card,
  Snackbar,
} from '@material-ui/core';
import { useAuth } from '../../../context/authContext';
import { useSelector, useDispatch } from 'react-redux';
import { Creators } from '../../../store/pmp/actions';
import Page from '../../../../src/components/Page';
import Alert from '../../../../src/components/Alert';
import DailyReport from './dailyReport';
import MaintenceReport from './maintenceReport';
import { Spacing } from './styles';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

const ControlListView = () => {
  const classes = useStyles();
  const { currentUser } = useAuth();
  const { machineList,  loading } = useSelector((state) => {
    return state.pmpReducer;
  });
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    dispatch(Creators.getServices());
    dispatch(Creators.getMachines());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Page
      className={classes.root}
      title="Controle Diário"
    >
      <Container maxWidth={false}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Máquina cadastrada com sucesso!
          </Alert>
        </Snackbar>
        <Box mt={3}>
          <Card >
            <DailyReport />
          </Card>
          <Spacing />
          <Card >
            <MaintenceReport />
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default ControlListView;
