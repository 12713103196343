import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(({
  root: {}
}));

const Password = ({ className, ...rest }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    password: '',
    passwordConfirmation: ''
  });

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const handleUploadPassword = async ({ password }) => {

  };

  return (
    <Formik
      initialValues={{
        password: '',
        passwordConfirmation: ''
      }}
      validationSchema={
        Yup.object().shape({
          password: Yup.string().max(255).min(6).required('Senha é obrigatório'),
          passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Senhas devem ser iguais'),
        })
      }
      onSubmit={(values) => {
        handleUploadPassword(values)
        // navigate('/app/central', { replace: true });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
    <form
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader
          title="Atualizar senha"
        />
        <Divider />
        <CardContent>
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Nova senha"
            margin="normal"
            name="password"
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
            fullWidth
            helperText={touched.passwordConfirmation && errors.passwordConfirmation}
            label="Confirme a nova senha"
            margin="normal"
            name="passwordConfirmation"
            onChange={handleChange}
            type="password"
            value={values.passwordConfirmation}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
            type="submit"
          >
            Atualizar senha
          </Button>
        </Box>
      </Card>
    </form>
    )}
    </Formik>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
