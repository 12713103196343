import React from 'react';

import { Link } from 'react-router-dom';

import DashboardLayout from '../src/layouts/DashboardLayout';
import MainLayout from '../src/layouts/MainLayout';

import DashboardView from '../src/views/central/DashboardView';
import MachineListView from '../src/views/machine/MachineListView';
import AddMachineView from '../src/views/machine/AddMachineView';
import UserListView from '../src/views/user/UserListView';
import ControlListView from '../src/views/control/ControlView';
import DemandListView from '../src/views/demands/DemandListView';
import AddDemandView from '../src/views/demands/AddDemandView';
import CalendarView from '../src/views/calendar/CalendarView';
import AccountView from '../src/views/account/AccountView';

import LoginView from '../src/views/auth/LoginView';
import RegisterView from '../src/views/auth/RegisterView';
import NotFoundView from '../src/views/errors/NotFoundView';
import LogoutView from '../src/views/auth/LogoutView';


const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'central', element: <DashboardView /> },
      { path: 'pessoas', element: <MachineListView /> },
      { path: 'pessoas/adicionar', element: <AddMachineView /> },
      { path: 'demandas', element: <DemandListView />},
      { path: 'agenda', element: <CalendarView /> },
      { path: 'demandas/adicionar', element: <AddDemandView /> },
      { path: 'usuarios', element: <UserListView /> },
      { path: 'controle', element: <ControlListView /> },
      { path: 'perfil', element: <AccountView /> },
      { path: 'sair', element: <LogoutView /> },
      { path: '*', element: <Link to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Link to="/app/central" /> },
      { path: '*', element: <Link to="/404" /> }
    ]
  }
];

export default routes;
