import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Box,
  Dialog,
  Typography,
  TextField,
  DialogContent,
  Button,
  Divider,
  colors,
  makeStyles
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import ListItem from '@material-ui/core/ListItem';
import { Formik } from 'formik';
import { ButtonContainer} from './styles';
import ListItemText from '@material-ui/core/ListItemText';
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.orange[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.green[900]
  },
  differenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  }
}));

const BirthList = ({ births, className, ...rest }) => {
  const classes = useStyles();
  const [personToText, setPersonToText] = useState(undefined);
  const [open, setOpen] = useState(false);

  const handleMenssage = ({message}) => {
    let a = document.createElement('a')
    a.target = '_blank'
    a.href = `https://api.whatsapp.com/send?phone=55${personToText.phone}&text=${message}` // o link URL do api.whatsapp.com
    a.click() // simular o evento de "click"
  };

  const handleMenssageDialog = (person) => {
    setPersonToText(person);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <Typography
            color="textPrimary"
            variant="h4"
          >
            Aniversariantes do dia
          </Typography>
        </div>
        <Divider />
        {isEmpty(births) ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center', 
              justifyContent: 'center',
               marginTop: '30px',
            }}
          >
            <Typography
              fontColor="#808080"
              variant="h5"
          >
            Nenhum aniversariante hoje
          </Typography>
        </div>
        ) : (
          <List>
            {
              births && births.map((person) => (
                <ListItem button key={person.id}>
                  <ListItemText primary={person.name} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="wpp" onClick={() => handleMenssageDialog(person)}>
                      <WhatsAppIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            }
          </List>
        )}
      </CardContent>
      <Dialog 
        open={open}
        fullWidth
        maxWidth={"md"}
        onClose={handleClose} 
        aria-labelledby="form-dialog-title">
        <Box >
          <DialogContent>
            <Formik
              initialValues={{
                message: '',
              }}
              onSubmit={(values) => {
                handleMenssage(values);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box sm={3}>
                    <Typography
                      color="textPrimary"
                      variant="h2"
                    >
                      Enviar mensagem para {personToText.name}
                    </Typography>
                  </Box>
                  <TextField
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                    label="Mensagem"
                    margin="normal"
                    name="message"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    multiline
                    rows={8}
                    variant="outlined"
                    fullWidth
                  />
                  <ButtonContainer>
                    <span>
                      <Button
                        color="primary"
                        fullWidth
                        size="small"
                        variant="outlined"
                        onClick={() => setOpen(false)}
                      >
                        Voltar
                      </Button>
                    </span>
                    <span>
                      <Button
                        color="primary"
                        fullWidth
                        size="small"
                        type="submit"
                        variant="contained"
                      >
                        Enviar
                      </Button>
                    </span>
                  </ButtonContainer>
                </form>
              )}
            </Formik>
          </DialogContent>
        </Box>
      </Dialog>
    </Card>
  );
};

BirthList.propTypes = {
  className: PropTypes.string
};

export default BirthList;
