const Types = {
  CREATE_PERSON: 'pmp/CREATE_PERSON',
  CREATE_DEMAND: 'pmp/CREATE_DEMAND',
  LOAD_MACHINE_LIST: 'pmp/LOAD_MACHINE_LIST',
  LOAD_MACHINE_LIST_COMPLETED: 'pmp/LOAD_MACHINE_LIST_COMPLETED',
  LOAD_PERSON_FILTERED_LIST: 'pmp/LOAD_PERSON_FILTERED_LIST',
  LOAD_PERSON_FILTERED_LIST_COMPLETED: 'pmp/LOAD_PERSON_FILTERED_LIST_COMPLETED',
  LOAD_PERSON_FILTERED_LIST_ERROR: 'pmp/LOAD_PERSON_FILTERED_LIST_ERROR',
  LOAD_DEMAND_LIST: 'pmp/LOAD_DEMAND_LIST',
  LOAD_DEMAND_LIST_COMPLETED: 'pmp/LOAD_DEMAND_LIST_COMPLETED',
  LOAD_DEMAND_FILTERED_LIST: 'pmp/LOAD_DEMAND_FILTERED_LIST',
  LOAD_DEMAND_FILTERED_LIST_COMPLETED: 'pmp/LOAD_DEMAND_FILTERED_LIST_COMPLETED',
  LOAD_DEMAND_FILTERED_LIST_ERROR: 'pmp/LOAD_DEMAND_FILTERED_LIST_ERROR',
  LOAD_TOTAL_MAINTENCE_COST: 'pmp/LOAD_TOTAL_MAINTENCE_COST',
  LOAD_TOTAL_MAINTENCE_COST_COMPLETED: 'pmp/LOAD_TOTAL_MAINTENCE_COST_COMPLETED',
  CREATE_DAILY: 'pmp/CREATE_DAILY',
  CREATE_MAINTENCE: 'pmp/CREATE_MAINTENCE',
  CREATE_USER: 'pmp/CREATE_USER',
  CREATE_USER_COMPLETED: 'pmp/CREATE_USER_COMPLETED',
  LOAD_USER_LIST: 'pmp/LOAD_USER_LIST',
  LOAD_USER_LIST_COMPLETED: 'pmp/LOAD_USER_LIST_COMPLETED',
  CREATE_SERVICE: 'pmp/CREATE_SERVICE',
  CREATE_SERVICE_COMPLETED: 'pmp/CREATE_SERVICE',
  LOAD_SERVICE_LIST: 'pmp/LOAD_SERVICE_LIST',
  LOAD_SERVICE_LIST_COMPLETED: 'pmp/LOAD_SERVICE_LIST_COMPLETED',
  DELETE_SERVICE: 'pmp/DELETE_SERVICE',
  CREATE_SERVICE_REPORT: 'pmp/CREATE_SERVICE_REPORT',
  LOAD_REPORT_INTO_SERVICE: 'pmp/LOAD_REPORT_INTO_SERVICE',
  LOAD_REPORT_INTO_SERVICE_COMPLETED: 'pmp/LOAD_REPORT_INTO_SERVICE_COMPLETED'
};

export default Types;
